// Chakra imports

import React, {useCallback, useEffect, useState} from "react";
import ComplexTable from 'views/admin/default/components/ComplexTable';

import {
    Box, useColorModeValue, Input
} from '@chakra-ui/react';
import {useAuthHeader, useSignOut} from "react-auth-kit";
import {useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";
import {Schedule} from "../models";
import {NavigationDataService} from "../../../../service/NavigationDataService";


/**
 * 
 * This view includes the following filter fields:
 * 1 -> Weekday
 * 2 -> Station 
 * 3 -> 
 * 
 * The Table displays the time slots associated with a specific station 
 * 
 */
export default function RouteSchedules(props: any) {

    const authHeader = useAuthHeader()
    const signOut = useSignOut();
    const history = useHistory();
    const [cookies] = useCookies(['active_provider']);
    const [scheduleData, setScheduleData] = useState([] as Schedule[]);
    const [tableData, setTableData] = React.useState([]);
    const [tableColumns, setTableColumns] = React.useState([])
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'white');


    const mapTableRows = (rows: Schedule[]) => {
        const tableRows: { name: string; accessor: string; value: React.JSX.Element; }[][] = [];
        const groupedData: Map<number, Schedule[]> = rows
        .sort((a: Schedule, b: Schedule) => a.row - b.row)
        .reduce(
            (entryMap: any, e: any) => entryMap.set(
                e.stationId, [...entryMap.get(e.stationId)||[], e]
            ), new Map()
        );

        groupedData.forEach((value: Schedule[], row: number) => {
            let cols = [];
            cols.push({
                name: 'Station',
                accessor: 'station',
                value: (
                    <Box>
                        {value[0].stationName}
                    </Box>
                )
            });

            cols.push(...value
            .sort((a: Schedule, b: Schedule) => {
                const aDate = new Date();
                const bDate = new Date();

                aDate.setHours(parseInt(a.time.split(':')[0]));
                aDate.setMinutes(parseInt(a.time.split(':')[1]));
                bDate.setHours(parseInt(b.time.split(':')[0]));
                bDate.setMinutes(parseInt(b.time.split(':')[1]));

                return aDate < bDate ? -1 : 1
            })
            .map((schedule: Schedule, i: number) => {
                return {
                    accessor: 'time_col_' + i,
                    name: schedule.scheduleFrequency,
                    value: (
                        <Box style={{maxWidth: '5rem'}}>
                            <TableInput type='time' val={schedule.time} onChange={console.log} />
                        </Box>
                    )
                }
            }));

            tableRows.push(cols);
        });

        return tableRows;
    }

    const initialiseTableData = (data: Schedule[]) => {
        console.log('Initialising table data', data)
        const tableRows = mapTableRows(data);

        const largestRow = tableRows
        .sort((a: any, b: any) => a.length - b.length)[0]

        setTableColumns(largestRow.map((k: any) => {
            return {
                accessor: k.accessor,
                Header: k.name
            }
        }));

        setTableData(tableRows.map((row: any) => {
            let obj: any = {};
            row.forEach((col: any) => {
                obj[col.accessor] = col.value;
            });
            return obj;
        }));
        setScheduleData(data);

        console.log('Table data: ', tableRows);
    };

    useEffect(() => {
        const fetchRowData = async () => {
            const schedules: Schedule[] = [];
            let rowData: Schedule[] = [];
            let rowNum = 1;
            let emptyCount = 0;

            console.log('Fetching row data for route: ', props);

            try {
                do {
                    const res = await NavigationDataService
                        .getRouteSchedules(props.route.routeId,rowNum++)
                    rowData = res.data;

                    if (rowData.length === 0) {
                        ++emptyCount;
                    } else {
                        schedules.push(...rowData);
                        emptyCount = 0;
                    }
                } while (emptyCount < 5 && rowNum < 15);

                console.log('Fetched row data: ', schedules);
                return schedules;

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchRowData().then(initialiseTableData).catch(console.error);

    }, []);

    return (tableData.length > 0 && tableColumns.length > 0 &&
        (<Box maxHeight={'40vh'} overflowY={'auto'}>
            <ComplexTable columnsData={tableColumns} tableData={tableData} />
        </Box>)
    );
}

const TableInput = (props: {type: string; val: any; onChange: any}) => {

    const [inputVal, setInputVal] = useState(props.val)

    const handleChange = (e: any) => {
        setInputVal(e.target.value);
        props.onChange(e.target.value);
    }

    return (
        <Input type={props.type} value={inputVal} onChange={handleChange} variant='unstyled' placeholder='Unstyled'  />
    )
}