// Chakra imports
import { Box, Flex, Heading, Icon, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import IconBox from 'components/icons/IconBox';
// Assets
import { MdThumbDownAlt, MdThumbsUpDown, MdThumbUpAlt } from 'react-icons/md';

export default function Feedback(props: {
    title: string;
    ranking: number;
    comment: string;
    date: string;
    [x: string]: any;
}) {
    const { title, ranking, comment, date, ...rest } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const brandColor = useColorModeValue('brand.500', 'white');
    const bg = useColorModeValue('white', 'navy.700');
    return (
        <Card bg={bg} {...rest} p='14px' m={4}>

		
            <Flex direction={{ base: 'row'}} gap={3}>
                <IconBox
                    w='42px'
                    h='42'
                    icon={<Icon w='25px' h='25px' as={ranking === 1 ? MdThumbUpAlt : MdThumbDownAlt} color={ranking === 1 ? brandColor : 'red.500'} />}
                />
                <Box mt={{ base: '10px', md: '0' }}>
                    <Text color={textColorPrimary} fontWeight='500' fontSize='md' mb='4px'>
                        {title}
                    </Text>
                    <Text color={textColorPrimary} fontWeight='400' fontSize='sm' me='4px'>
                        {comment}
                    </Text>
					
                </Box>
                <Spacer />
                <Text fontWeight='400' color={textColorSecondary} fontSize='sm' me='12px' minW={'120px'}>
                    {date}
                </Text>
            </Flex>
        </Card>
    );
}
