import {
    Avatar,
    Box,
    Button,
    Flex,
    Icon,
    Progress,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import React, { useMemo } from "react";
import { MdAltRoute, MdInfo } from "react-icons/md";
import {CgDanger} from "react-icons/cg";
import {ImWarning} from 'react-icons/im';
import {RiAlarmWarningFill} from 'react-icons/ri';
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";

function RecentActivity(props: { columnsData: any; tableData: any }) {
    const { columnsData, tableData } = props;

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'white');

    const severityToIcon = (severity: string) => {
        let icon, color;
        switch (severity?.toLowerCase()) {
            case 'severe': 
                icon = CgDanger;
                color = 'green';
                break;

            case 'moderate':
                icon = ImWarning;
                color = 'red';
                break;
            case 'minor':
                icon = RiAlarmWarningFill;
                color = 'orange';
                break;
      
            default:  
                icon = MdInfo;
                color = 'gray';
                break;
        }

        return (
            <Icon
                transition='0.2s linear'
                w='20px'
                h='20px'
                as={icon}
                color={color}
            />
        )
    }

    const convertISOTime = (isoDate: string) => {
        let date = new Date(isoDate);
        return date.toLocaleString();
    }
  

    return (
    <>
      <Flex
          direction='column'
          w='100%'
          overflowX={{ sm: "scroll", lg: "hidden" }}>
          <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              pb='20px'
              mb='10px'
              boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
              <Text color={textColor} fontSize='xl' fontWeight='600'>
            Recent Activity
              </Text>
              <Button variant='action'>See all</Button>
          </Flex>
          <Table {...getTableProps()} variant='simple' color='gray.500'>
          

              <Tbody {...getTableBodyProps()}>
                  {page.map((row, index) => {
                      prepareRow(row);
                      return (
                          <Tr {...row.getRowProps()} key={index}>
                              {row.cells.map((cell, index) => {
                                  let data ;
                                  if (cell.column.Header === "Incident") {
                                      data = (
                                          <Flex align='center'>
                                              <IconBox
                                                  w='26px'
                                                  h='26px'
                                                  me='8px'
                                                  bg={boxBg}
                                                  icon={severityToIcon(cell.value[0])}
                                              />
                                              <Text
                                                  color={textColor}
                                                  fontSize='sm'
                                                  fontWeight='600'>
                                                  {cell.value[1].charAt(0).toUpperCase() + cell.value[1].slice(1)}
                                              </Text>
                                          </Flex>
                                      );
                                  } else if (cell.column.Header === "Date") {
                                      data = (
                                          <Text
                                              color={textColor}
                                              fontSize='sm'
                                              fontWeight='600'>
                                              {convertISOTime(cell.value)}
                                          </Text>
                                      );
                                  } else {
                                      data = (
                                          <Text
                                              color={textColorSecondary}
                                              fontSize='sm'
                                              fontWeight='500'>
                                              {cell.value}
                                          </Text>
                                      );
                                  }
                                  return (
                                      <Td
                                          {...cell.getCellProps()}
                                          key={index}
                                          fontSize={{ sm: "14px" }}
                                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                          borderColor='transparent'>
                                          {data}
                                      </Td>
                                  );
                              })}
                          </Tr>
                      );
                  })}
              </Tbody>
          </Table>
      </Flex>
    </>
    );
}

export default RecentActivity;
