// Chakra imports
import { AvatarGroup, Avatar, Box, Button, Flex, Icon, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
// Assets
import { MdBarChart, MdWifiCalling3 } from 'react-icons/md';
import { RiStopCircleFill } from 'react-icons/ri';
import {TbCheckbox} from 'react-icons/tb';
import {GrStatusUnknown} from 'react-icons/gr';
import IconBox from 'components/icons/IconBox';
import { IoMdLocate } from 'react-icons/io';

export default function NFT(props: {
    image: string;
    name: string;
    region: string;
    ratings: string[];
    tripHrs: string | number;
    status?: string;
    contactInfo?: string;
}) {
    const { image, name, region , status, ratings, tripHrs, contactInfo } = props;
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorBid = useColorModeValue('black', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'white');

    const getStatusIcon = (status?: string) => {
        let icon, color;
        switch (status?.toLowerCase()) {
            case 'active': 
                icon = TbCheckbox;
                color = 'green';
                break;

            case 'inactive':
                icon = RiStopCircleFill;
                color = 'red';
                break;
            default:  
                icon = GrStatusUnknown;
                color = 'gray';
                break;

        }

        return (
            <Icon
                transition='0.2s linear'
                w='20px'
                h='20px'
                as={icon}
                color={color}
            />
        )
    }


    return (
        <Card p='20px'>
            <Flex direction={{ base: 'column' }} justify='center'>
                <Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
                    <Image
                        src={image}
                        w={{ base: '100%', '3xl': '100%' }}
                        h={{ base: '100%', '3xl': '100%' }}
                        maxHeight={'10rem'}
                        borderRadius='20px'
                    />
                    <Button						
                        position='absolute'
                        bg='white'
                        _hover={{ bg: 'whiteAlpha.900' }}
                        _active={{ bg: 'white' }}
                        _focus={{ bg: 'white' }}
                        p='0px !important'
                        top='14px'
                        right='14px'
                        borderRadius='50%'
                        minW='36px'
                        h='36px'
                    >
                        {getStatusIcon(status)}
                    </Button>
                </Box>
                <Flex flexDirection='column' justify='space-between' h='100%'>
                    <Flex
                        justify='space-between'
                        direction={{
                            base: 'row',
                            md: 'column',
                            lg: 'row',
                            xl: 'column',
                            '2xl': 'row'
                        }}
                        mb='auto'>
                        <Flex direction='column'>
                            <Text
                                color={textColor}
                                fontSize={{
                                    base: 'xl',
                                    md: 'lg',
                                    lg: 'lg',
                                    xl: 'lg',
                                    '2xl': 'md',
                                    '3xl': 'lg'
                                }}
                                mb='5px'
                                fontWeight='bold'
                                me='14px'>
                                {name}
                            </Text>
                            <Text
                                color='secondaryGray.600'
                                fontSize={{
                                    base: 'md'
                                }}
                                fontWeight='700'
                                me='14px'>
                                {region}
                            </Text>
                        </Flex>
                        <AvatarGroup
                            max={2}
                            color={textColorBid}
                            size='sm'
                            mt={{
                                base: '0px',
                                md: '10px',
                                lg: '0px',
                                xl: '10px',
                                '2xl': '0px'
                            }}
                            fontSize='12px'>
                            {ratings.map((avt, key) => <Link href={'#'} key={key}><Avatar src={avt} /></Link>)}
                        </AvatarGroup>
                    </Flex>
                    <Flex
                        align={{
                            base: 'center',
                            md: 'start',
                            lg: 'center',
                            xl: 'start',
                            '2xl': 'center'
                        }}
                        justify='space-between'
                        direction={{
                            base: 'row',
                            md: 'column',
                            lg: 'row',
                            xl: 'column',
                            '2xl': 'row'
                        }}
                        mt='25px'>
                        <Text fontWeight='700' fontSize='sm' color={textColorBid}>
							Total Trip Hrs: {tripHrs}
                        </Text>
                        <Flex gap={2}>
                            <IconBox
                                w='42px'
                                h='42'
                                bg={boxBg}
                                icon={<Icon w='25px' h='25px' as={IoMdLocate} color={brandColor} />}
                            />
                            <Link
                                href={'tel:' + contactInfo}
                                mt={{
                                    base: '0px',
                                    md: '10px',
                                    lg: '0px',
                                    xl: '10px',
                                    '2xl': '0px'
                                }}>
                                <IconBox
                                    w='42px'
                                    h='42'
                                    bg={boxBg}
                                    icon={<Icon w='25px' h='25px' as={MdWifiCalling3} color={brandColor} />}
                                />
                            </Link>
                        </Flex>
						
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
}
