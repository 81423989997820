import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes';

// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

// Layout components
import { SidebarContext } from 'contexts/SidebarContext';
import SignIn from 'views/auth/signIn';
import TermsOfService from "../../views/public/terms-of-service";

// Custom Chakra theme
export default function Public(props: { [x: string]: any }) {

    const authBg = useColorModeValue('white', 'navy.900');
    document.documentElement.dir = 'ltr';
    return (
        <Box>
            <Box
                bg={authBg}
                float='right'
                minHeight='100vh'
                height='100%'
                position='relative'
                w='100%'
                transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                transitionDuration='.2s, .2s, .35s'
                transitionProperty='top, bottom, width'
                transitionTimingFunction='linear, linear, ease'>
                <Box mx='auto' minH='100vh'>
                    <TermsOfService />
                </Box>
            </Box>
        </Box>
    );
}
