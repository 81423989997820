// Chakra Imports
import {
    Avatar,
    Button,
    Flex,
    Icon,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useColorMode,
    Box,
    Heading
} from '@chakra-ui/react';
// Custom Components
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import { useState } from 'react';
// Assets
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes';
import {useHistory} from "react-router-dom";
import {useAuthUser, useSignOut} from 'react-auth-kit'
import { useCookies } from 'react-cookie';
import { DataService } from 'service/MessageService';
import IconBox from 'components/icons/IconBox';







export default function HeaderLinks(props: { secondary: boolean }) {
    const { secondary } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    // Chakra Color Mode
    const navbarIcon = useColorModeValue('gray.400', 'white');
    let menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorBrand = useColorModeValue('brand.700', 'brand.400');
    const ethColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
    const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const ethBox = useColorModeValue('white', 'navy.800');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );
    const auth = useAuthUser();
    const signOut = useSignOut();
    const authUser = auth();

    const [cookies, setCookie] = useCookies(['active_provider']);
    const [activeProvider, setActiveProvider] = useState(cookies.active_provider);
    const [userInfo] = useState(authUser);
    const history = useHistory();


    const getOrganization = (id: any) => {
        return userInfo.linkedOrganizations.find((org: any) => org.id === parseInt(id));
    }


    const [selectedOrganization, setSelectedOrganization] = useState(getOrganization(activeProvider));

    const selectProvider = (org: any) => {
        if (activeProvider !== org.id) {

            setCookie('active_provider', org.id);
            setActiveProvider(org.id);
            setSelectedOrganization(getOrganization(org.id));
            DataService.send(org, 'ORGANIZATION');

        }
    };


    const logOut = () => {
        signOut();
        history.push('/auth/sign-in');
    }

    if (!userInfo || !selectedOrganization) {
        logOut();
    }
	

    return (
        !userInfo || !selectedOrganization ? <></> :
            <Flex
                w={{ sm: '100%', md: 'auto' }}
                alignItems='center'
                flexDirection='row'
                bg={menuBg}
                flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
                p='10px'
                borderRadius='30px'
                boxShadow={shadow}>
                <SearchBar
                    mb={() => {
                        if (secondary) {
                            return { base: '10px', md: 'unset' };
                        }
                        return 'unset';
                    }}
                    me='10px'
                    borderRadius='30px'
                />
			
                <Flex
                    bg={ethBg}
                    display={secondary ? 'flex' : 'none'}
                    borderRadius='30px'
                    ms='auto'
                    p='6px'
                    align='center'
                    me='6px'>
                    <Flex align='center' justify='center' bg={ethBox} h='29px' w='29px' borderRadius='30px' me='7px'>
                        <Icon color={ethColor} w='9px' h='14px' as={FaEthereum} />
                    </Flex>
                    <Menu>
                        <MenuButton
                            px={4}
                            py={2}
                            transition='all 0.2s'
                            border={'none'}
                        >
                            <Text w='max-content' color={ethColor} fontSize='sm' fontWeight='700' me='6px'>
                                {selectedOrganization.name}							
                            </Text>
						
                        </MenuButton>
                        <MenuList>
                            {
                                userInfo.linkedOrganizations.map((org: any, i: number) => (
                                    <MenuItem minH='48px' key={i} onClick={() => selectProvider(org)}>
									
                                        <Flex position='relative' align='center' >
                                            <Image
                                                boxSize='2rem'
                                                borderRadius='8px'
                                                src={org.logo}
                                                alt=''
                                                mr='12px'
                                                w='7.1rem' h='7.1rem'
                                            />
                                            <Box gap={4}>
                                                <Flex
                                                    direction='column'
                                                    w={{ base: '70%', md: '100%' }}
                                                    me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }}>
                                                    <Heading as={'h4'}
                                                        color={ethColor}
                                                        fontSize={{
                                                            base: 'lg'
                                                        }}
                                                        fontWeight='bold'>
                                                        {org.name}
                                                    </Heading>
                                                    <Text
                                                        color='blackAlpha.700'
                                                        fontSize={{
                                                            base: 'md'
                                                        }}
                                                        fontWeight='600'
                                                        me='14px'>
													Total Branches: {org.branches.length}
                                                    </Text>
                                                    <Text
                                                        color='blackAlpha.700'
                                                        fontSize={{
                                                            base: 'md'
                                                        }}
                                                        fontWeight='600'
                                                        me='14px'>
                                                        <strong>Role:</strong> {org.role}
                                                    </Text>
                                                </Flex>
                                            </Box>
										
										
                                        </Flex>
									
                                    </MenuItem>
                                ))
                            }
						
						
                        </MenuList>
                    </Menu>
                </Flex>
                <SidebarResponsive routes={routes} />
                <Menu>
                    <MenuButton p='0px'>
                        <Icon mt='6px' as={MdNotificationsNone} color={navbarIcon} w='18px' h='18px' me='10px' />
                    </MenuButton>
                    <MenuList
                        boxShadow={shadow}
                        p='20px'
                        borderRadius='20px'
                        bg={menuBg}
                        border='none'
                        mt='22px'
                        me={{ base: '30px', md: 'unset' }}
                        minW={{ base: 'unset', md: '400px', xl: '450px' }}
                        maxW={{ base: '360px', md: 'unset' }}>
                        <Flex w='100%' mb='20px'>
                            <Text fontSize='md' fontWeight='600' color={textColor}>
							Notifications
                            </Text>
                            <Text fontSize='sm' fontWeight='500' color={textColorBrand} ms='auto' cursor='pointer'>
							Mark all read
                            </Text>
                        </Flex>
                        <Flex flexDirection='column'>
                            <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px='0' borderRadius='8px' mb='10px'>
							No unread notifications
                            </MenuItem>
                            {/* <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px='0' borderRadius='8px' mb='10px'>
							<ItemContent info='Horizon UI Dashboard PRO' />
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px='0' borderRadius='8px' mb='10px'>
							<ItemContent info='Horizon Design System Free' />
						</MenuItem> */}
                        </Flex>
                    </MenuList>
                </Menu>
                <IconBox
                    p='0'
                    icon={<Icon as={MdInfoOutline} color={navbarIcon} w='18px' h='18px' me='10px' />}
                    onClick={() => history.push('/admin/v1/docs')}
                />
                <Button
                    variant='no-hover'
                    bg='transparent'
                    p='0px'
                    minW='unset'
                    minH='unset'
                    h='18px'
                    w='max-content'
                    onClick={toggleColorMode}>
                    <Icon
                        me='10px'
                        h='18px'
                        w='18px'
                        color={navbarIcon}
                        as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
                    />
                </Button>
                <Menu>
                    <MenuButton p='0px'>
                        <Avatar
                            _hover={{ cursor: 'pointer' }}
                            color='white'
                            name={`${userInfo.firstName} ${userInfo.lastName}`}
                            bg='#11047A'
                            size='sm'
                            w='40px'
                            h='40px'
                        />
                    </MenuButton>
                    <MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={menuBg} border='none'>
                        <Flex w='100%' mb='0px'>
                            <Text
                                ps='20px'
                                pt='16px'
                                pb='10px'
                                w='100%'
                                borderBottom='1px solid'
                                borderColor={borderColor}
                                fontSize='sm'
                                fontWeight='700'
                                color={textColor}>
							👋&nbsp; Hey, {userInfo.firstName}
                            </Text>
                        </Flex>
                        <Flex flexDirection='column' p='10px'>
                            <MenuItem 
                                _hover={{ bg: 'none' }} 
                                _focus={{ bg: 'none' }} 
                                borderRadius='8px'
                                px='14px'
                                onClick={() => history.push('/admin/profile')}
                            >
                                <Text fontSize='sm'>Profile Settings</Text>
                            </MenuItem>
                            <MenuItem
                                onClick={logOut}
                                _hover={{ bg: 'none' }}
                                _focus={{ bg: 'none' }}
                                color='red.400'
                                borderRadius='8px'
                                px='14px'>
                                <Text fontSize='sm'>Log out</Text>
                            </MenuItem>
                        </Flex>
                    </MenuList>
                </Menu>
            </Flex>
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func
};