import {MarkerF} from '@react-google-maps/api'
import React, { useEffect, useRef, useState, useMemo } from "react";
import { DirectionsService, GoogleMap, TransitLayer, useJsApiLoader, DirectionsRenderer, Marker, InfoWindow, Polyline } from '@react-google-maps/api';
import busStopIcon from 'assets/img/maps/bus-stop.png';
import busStationIcon from 'assets/img/maps/bus-station.png';
import trainStopIcon from 'assets/img/maps/train-station.png';
import {RouteData, RouteTableData, StationData} from "../models";

const containerStyle = {
    width: '100%',
    height: '60vh'
};

  
function MapContainer(props: any) {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAoOi4TaLOBLCHCVaIBoOShHdsJmKMzODo"
    })
  
    const [infoWindows, setInfoWindows] = useState([]);
    const [center, setCenter] = useState(props.center);
    const [map, setMap] = React.useState(null)
    const colors = ['#e69138', '#445E93', '#F93943', '#3d85c6', '#86BAA1']
    const [lines, setLines] = useState([])
    const [stations, setStations] = useState([])
    const [directions, setDirections] = useState([]);


    const directionsCallback = (response: any, routeInput: any) => {
        if (response !== null) {
            if (response.status === 'OK') {
                setDirections((directions: any[]) => {
                    const direction = directions.find(d => d.routeId === routeInput.id);

                    if (direction) {
                        direction.results = response;
                    } else {
                        directions.push({routeId: routeInput.id, results: response})
                    }

                    if (props.onDirectionsChanged) {
                        const line = lines.find(line => line.data.routeId === routeInput.id);
                        if (line && (line.data?.stations?.length || 0) > 0) {
                            const coords = toLatLong(line.data.stations[0].currentStop);
                            props.onDirectionsChanged(coords);
                        }

                    }
                    return directions;
                })
            }
        }
    }

    const HtmlInfoWindow = (props: any) => {
        const {position, content} = useMemo(() => props, [props]);
        const windowRef = useRef();

        // @ts-ignore
        windowRef["current"].innerHTML = content;

        return (
            <InfoWindow
                position={position}
            >
                <div contentEditable={true} ref={windowRef}> </div>
            </InfoWindow>
        )
    }

    const onLoad = React.useCallback((map: any) => {
        if (center) {
            map.setCenter(center);
            map.setZoom(12);
        }
        
        setMap(map)
    }, [center])
    const onUnmount = React.useCallback((map: any) => {
        setMap(null)
    }, []);

    useEffect(() => {
        const groupedData: Map<number, RouteData[]> = props.routes.reduce(
            (entryMap: any, e: any) => entryMap.set(
                e.routeId, [...entryMap.get(e.routeId)||[], e]
            ), new Map()
        );
        const stations: {marker: any, data: RouteData}[] = [];
        const lines: {line: any, data: RouteTableData}[] = [];

        groupedData.forEach((value: RouteData[], routeId: number) => {
            const routeStations = value.map((station: RouteData) => {
                const marker = {
                    position: toLatLong(station.currentStop),
                    icon: station.transitType.name.toLowerCase() === 'bus' ? busStopIcon : trainStopIcon,
                    title: station.currentStop?.name,
                    infoWindow: `
                            Name: ${station.currentStop?.name}
                            Next Station: ${station.nextStop?.name}
                            Next Departure Time: ${station.eta}
                        `
                };
                return {marker, data: station};
            });

            const line = {
                path: value
                .sort((a, b) => a.stationIndex - b.stationIndex)
                .map(station => toLatLong(station.currentStop))
            };


            if (line.path.length > 2) {
                const origin = line.path[0];
                const destination = line.path[line.path.length - 1];
                const waypoints = line
                    .path
                    .slice(1, line.path.length - 1)
                    .map(pt => {
                        return {
                            location: pt,
                            stopover: true
                        }
                    });

                console.log('Line', line);
                lines.push({
                    line: {
                        destination: destination,
                        origin: origin,
                        travelMode: 'DRIVING',
                        optimizeWaypoints: false,
                        provideRouteAlternatives: false,
                        waypoints: waypoints
                    },
                    data: {routeId, stations: value}
                });
            }

            stations.push(...routeStations);

        });

        setStations(stations);
        setLines(lines);
        console.log(stations, lines);

        let center: any;

        if (stations.length > 0) {
            center = stations[0].marker.position;
        } else {
          center = props.center;
        }

        if (map) {
            map.setCenter(center);
            map.setZoom(14);
        }


    }, [props])

  
    return isLoaded ? (
        <GoogleMap
            id='commute-maps'
            mapContainerStyle={containerStyle}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onDragEnd={console.log}
            onClick={console.log}
        >
            {stations && stations.length > 0 && stations.map((station, i) => {
                return (
                    <Marker
                        key={`station-${i}`}
                        icon={station.marker.icon}
                        title={station.marker.title}
                        position={station.marker.position}
                    />
                )
            })}
            {lines && lines.length > 0 && lines.map((line, i) => {
                    return (<DirectionsService
                        key={'directions_' + i}
                        options={line.line}
                        callback={(response) => directionsCallback(response, line)}
                    />)
                })
            }
            {directions && directions.length > 0 && directions
                .filter(route => route.results?.status === "OK")
                .map((line, i) => (
                    <DirectionsRenderer
                        key={'reader_' + i}
                        options={{
                            directions: line.results,
                            draggable: false,
                            hideRouteList: false,
                            markerOptions: {
                                clickable: true,
                                cursor: 'pointer',
                                draggable: false,
                                icon: busStopIcon,
                                optimized: true,
                                visible: true
                            },
                            polylineOptions: {
                                strokeColor: colors[i % colors.length],
                                strokeOpacity: 0.8,
                                strokeWeight: 4,
                            }
                        }}
                    />
                ))
            }

            {infoWindows || (<></>)}
            
            <TransitLayer 
                onLoad={console.log}
            ></TransitLayer>
        </GoogleMap>
    ) : <></>
}
  
export default React.memo(MapContainer)

export const toLatLong = (station: StationData) => {
    return {lat: station.lat, lng: station.lng};
};

