// Chakra imports
import { Button, Flex, Link, Text, Image } from '@chakra-ui/react';

// Assets
import banner from 'assets/img/operations/banner3.jpg';

export default function Banner() {
    // Chakra Color Mode

    const textStyles = {
        fontSize: '2.2em',
        color: 'white',
        fontWeight: 800,
        marginBottom: '40px',
        maxWidth: '72%',
        background: 'rgba(94,93,93,0.33)',
        padding: '1rem'
    };

    return (
        <Image
            borderRadius='30px'
            border='1px solid #E2E8F0'
            width='95vw'
            height='45vh'
            mt='30px'
            m={4}
            objectFit='cover'
            src={banner}
            alt='Terms of Service'
        />
        // <Flex
        //     direction='column'
        //     bgImage={banner}
        //     bgSize='stretch'
        //     py={{ base: '30px', md: '95px' }}
        //     px={{ base: '30px', md: '64px' }}
        //     borderRadius='30px'
        //     style={{
        //         backgroundRepeat: 'no-repeat',
        //         minHeight: '25h',
        //         width: '95vw',
        //         justifyContent: 'center',
        //     }}
        // >
        //
        // </Flex>
    );
}
