import {
    Box, Button,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    SimpleGrid,
    Stack,
    Switch,
    useColorModeValue
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import MiniStatistics from "../../../../components/card/MiniStatistics";
import IconBox from "../../../../components/icons/IconBox";
import {MdAddTask, MdBarChart, MdDirectionsBus, MdFileCopy, MdTaxiAlert} from "react-icons/md";
import {useState} from "react";

export default function ITraffic() {

    let regionData = {
        label: 'Region',
        key: 'region',
        options: [
            {label: 'Gauteng', value: 'GP'},
            {label: 'Western Cape', value: 'WC'},
            {label: 'KZN', value: 'KZN'},
        ],
        selected: ['GP']
    }
    let filterData = [
        {label: 'Incidents', selected: true},
        {label: 'Construction', selected: true},
        {label: 'Closures', selected: true},
        {label: 'Congestion', selected: true},
        {label: 'TrafficSpeeds', selected: true},
        {label: 'WeatherForecast', selected: false},
        {label: 'Cameras', selected: false},
        {label: 'MessageSigns', selected: false}
    ];

    const ItrafficView = () => {
        const [region, setRegion] = useState(regionData);
        const [filters, setFilters] = useState( filterData );

        const generateUrl = (regionValue: any, filters: any[]) => {
            const base = 'https://www.i-traffic.co.za/Map/EmbeddedMap?';
            const region = `&region=${regionValue.selected}`;
            const layers = `&layers=${filters.filter(f => f.selected).map(f => f.label).join(',')}`;

            return `${base}${region}${layers}&size=1`;
        };

        const [url, setUrl] = useState(generateUrl(regionData, filterData));

        return (
            <Box>


                <Card>


                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>


                        <Box mr={4} style={{  height: '100%', maxHeight: '481px' }}>
                            <ItrafficRegions region={region} onChange={(data: any) => {
                                setRegion(data.region);
                                setUrl(generateUrl(data.region, filters));
                            }}/>
                            <ItrafficFilters filters={filters} onChange={(data: any) => {

                                setFilters(data.filters);
                                setUrl(generateUrl(region, data.filters));
                            }}/>

                        </Box>
                        <Box>

                            <iframe title={'i-traffic'} src={url} style={
                                {
                                    width: '100%',
                                    height: '100%', maxHeight: '481px', minHeight: '481px'
                                }
                            } />
                        </Box>


                    </SimpleGrid>
                </Card>

            </Box>


        )
    };




    const ItrafficFilters = (props: any) => {

        const {filters, onChange} = props;

        const filterChanged = (filter: any) => {
            filters.find((f: any) => f.label === filter.label).selected = !filter.selected;
            onChange({filters});
        }


        return (
            <Stack spacing={2} py={'2rem'}>
                <Heading size="md" mb={'1rem'}>Apply Filters</Heading>

                {
                    filters.map((filter: any, i: number) => {
                        return (
                            <FormControl key={'filter_' + i} display='flex' alignItems='center'>
                                <Switch id={filter.label}
                                    isChecked={filter.selected}
                                    onChange={() => filterChanged(filter)}
                                />
                                <FormLabel htmlFor='email-alerts' mb='0' ml={'2rem'}>
                                    {filter.label}
                                </FormLabel>
                            </FormControl>
                        )
                    })
                }
            </Stack>

        )
    };

    const ItrafficRegions = (props: any) => {

        const {region, onChange} = props;
        const regionChangedEvent = (option: any) => {
            region.selected = option.value;
            onChange({region});
        }

        return (
            <Box>
                <Heading size="md" mb={'1rem'}>Select Region</Heading>
                <Stack direction='row' spacing={4}>
                    {
                        region.options.map((option: any, index: number) => {
                            const isSelected = region.selected.includes(option.value);
                            return (
                                <Button key={'region' + index}
                                    size={'md'}
                                    onClick={() => regionChangedEvent(option)}
                                    variant={'solid'}
                                    colorScheme={isSelected ? 'blue' : 'teal'}
                                    borderRadius={'5px'}
                                    // style={{minWidth: '125px'}}
                                >{option.label}</Button>
                            )
                        })

                    }
                </Stack>
            </Box>

        )
    };



    return (
        <ItrafficView />
    )
}