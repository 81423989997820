// Chakra imports

import {useState} from "react";
import { Avatar, Flex, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import {
    Box, useColorModeValue, Grid, GridItem, Tab, TabList, TabPanel, TabPanels, Tabs
} from '@chakra-ui/react';
import {useAuthHeader, useSignOut} from "react-auth-kit";
import {useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";
import Card from "components/card/Card";
import TicketInformation from "./TicketInformation";
import TripOverview from "./TripOverview";
import { createTheme, ThemeProvider } from "@mui/material";
import { configureAxios } from "views/auth/signIn";
import banner from 'assets/img/operations/banner3.jpg';
import avatar from 'assets/img/avatars/avatar4.png';
import Information from "views/admin/profile/components/Information";
import { FaBusAlt, FaShuttleVan } from "react-icons/fa";
import { IconType } from "react-icons";
import { RiBusFill, RiTrainLine } from "react-icons/ri";


export default function VehicleInfo(props: {vehicle: any}) {

    const authHeader = useAuthHeader()
    const signOut = useSignOut();
    const history = useHistory();
    const [cookies] = useCookies(['active_provider']);
	

    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'white');

    const [isReaderActive, setReaderState] = useState(false);


    configureAxios(
        authHeader(),
        signOut,
        history
    );


    return (
        <Grid
            templateColumns='repeat(5, 1fr)'
            gap={4}
        >
            <GridItem colSpan={2}>
			
                <VehicleBanner
                    gridArea='1 / 1 / 2 / 2'
                    banner={banner}
                    avatar={avatar}
                    name='Adela Parkson'
                    job='Product Designer'
                    posts='17'
                    followers='9.7k'
                    following='274'
                />
            </GridItem>
            <GridItem colSpan={3}>
                <Tabs variant={'unstyled'}>
                    <TabList>
                        <Tab _selected={{color: brandColor, bg: boxBg}}>Details</Tab>
                        <Tab _selected={{color: brandColor, bg: boxBg}}>Service History</Tab>
                        <Tab _selected={{color: brandColor, bg: boxBg}}>Performance</Tab>

                    </TabList>

                    <TabPanels>
                        <TabPanel>
						    <VehicleDetails minH='365px' />
                        </TabPanel>
                        <TabPanel>
                            <ThemeProvider theme={createTheme()}>
                                <TripOverview />
                            </ThemeProvider>							
                        </TabPanel>
                        <TabPanel>
						    <TicketInformation minH='365px' />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
				
            </GridItem>
			
        </Grid>

    );
}

const VehicleBanner = (props: {
    banner: string;
    avatar: string;
    name: string;
    job: string;
    posts: number | string;
    followers: number | string;
    following: number | string;
    [x: string]: any;
}) => {
    const { banner, avatar, name, job, posts, followers, following, ...rest } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const borderColor = useColorModeValue('white !important', '#111C44 !important');
    return (
        <Card mb={{ base: '0px', lg: '20px' }} alignItems='center' {...rest}>
            <Box bg={`url(${banner})`} bgSize='cover' borderRadius='16px' h='131px' w='100%' />
            <Avatar 
                mx='auto' h='87px'
				 w='87px' mt='-43px'
				  border='4px solid' 
				  borderColor={borderColor} 
				  icon={<Icon as={getVehicleTypeIcon('BUS')} />}
            />
            <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
                {name}
            </Text>
            <Text color={textColorSecondary} fontSize='sm'>
                {job}
            </Text>
            <Flex w='max-content' mx='auto' mt='26px'>
                <Flex mx='auto' me='60px' alignItems='center' flexDirection='column'>
                    <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
                        {posts}
                    </Text>
                    <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
						Posts
                    </Text>
                </Flex>
                <Flex mx='auto' me='60px' alignItems='center' flexDirection='column'>
                    <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
                        {followers}
                    </Text>
                    <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
						Followers
                    </Text>
                </Flex>
                <Flex mx='auto' alignItems='center' flexDirection='column'>
                    <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
                        {following}
                    </Text>
                    <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
						Following
                    </Text>
                </Flex>
            </Flex>
        </Card>
    );
}

const getVehicleTypeIcon = (type: string): IconType => {

    switch (type.toLowerCase()) {
        case 'BUS': return RiBusFill;
        case 'TRAIN': return RiTrainLine;
        case 'TAXI': return FaShuttleVan;
        default: return FaBusAlt;

    }
}

const VehicleDetails = (props: { [x: string]: any }) => {
    const { ...rest } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
    const data: any = {
        code: 'EC5461SCE',
        status: ((Math.random() * 100) + 10) % 2 === 0 ? 'ACTIVE' : 'USED',
        belongsTo: 'Someone',
        purchaseDate: new Date().toLocaleString(),
        expiryDate: new Date().toLocaleString(),
        type: 'SINGLE USE',
    };

    const splitCase = (str: string) => {
        return str.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()
	  };

    return (
        <Card mb={{ base: '0px', '2xl': '20px' }} {...rest} alignSelf={'center'}>
            <Text color={textColorPrimary} align='center' fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Ticket Information
            </Text>
			
            <SimpleGrid columns={2} gap='20px'>
                {
                    Object.keys(data).map((k, i) => {
                        return (
                            <Information key={'info_' + i} title={splitCase(k)} value={ data[k]} />
                        )
                    })
                }
            </SimpleGrid>
        </Card>
    );
}
