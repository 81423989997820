// Chakra imports
import {Flex, Img, useColorModeValue} from '@chakra-ui/react';
import logo from '../../../assets/img/logo.png';

// Custom components
import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
    //   Chakra color mode
    let logoColor = useColorModeValue('navy.700', 'white');
    const logoStyles = {
        // maxHeight: '14rem',
    }

    return (
        <Flex alignItems='center' flexDirection='column'>
            <Img src={logo} style={logoStyles} />
            <HSeparator mb='20px' />
        </Flex>
    );
}

export default SidebarBrand;
