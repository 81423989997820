import { Subject } from 'rxjs';



const subject = new Subject();

export const DataService = {
    send: (e: any, type: any) => subject.next({type, data: e}),
    clear: () => subject.next({}),
    onMessage: () => subject.asObservable()
};