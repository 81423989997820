// Chakra imports

import React, {useEffect} from "react";
import ComplexTable from 'views/admin/default/components/ComplexTable';
import axios from "axios";
import {
    Box, useColorModeValue, ButtonGroup, Button, Badge, Tab, TabList, TabPanel, TabPanels, Tabs
} from '@chakra-ui/react';
import {useAuthHeader, useSignOut} from "react-auth-kit";
import {useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";
import { FcViewDetails } from "react-icons/fc";
import {BiQrScan} from  'react-icons/bi';
import OperationsFilter from "./Filter";
import TicketScan from "./TicketScan";



export default function TicketingView() {

    const authHeader = useAuthHeader()
    const signOut = useSignOut();
    const history = useHistory();
    const [cookies] = useCookies(['active_provider']);

    const columns = [
        {
            Header: "Owner",
            accessor: "belongsTo",
        },
        {
            Header: "Ticket Code",
            accessor: "code",
        },
		
        {
            Header: "Purchase Date",
            accessor: "purchaseDate",
        },	
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Type",
            accessor: "type",
        },
        {
            Header: "",
            accessor: "actions", // details, claim, transfer
        }
    ];
    const [tableData, setTableData] = React.useState([]);
    const boxBg = useColorModeValue('blue.600', 'white');
    const brandColor = useColorModeValue('white', 'blue.600');

    const badgeColorScheme = (status: string) => {
        switch (status?.toUpperCase()) {
            case 'ACTIVE': return 'teal';
            case 'USED': return 'gray';
            default: return 'gray';
        }
    }

    useEffect(() => {
        let isMounted = true;

        async function fetchData(filters: any) {

            const results = [];

            for (let i = 0; i < 7; i++) {

                const purchaseDate = new Date();

                purchaseDate.setDate(purchaseDate.getDate() - ((Math.random() * 100)));
                purchaseDate.setHours(i);

                const expiry = new Date();
                expiry.setDate(expiry.getDate() + ((Math.random() * 100)))

                const data = {
                    code: 'EC5461SCE',
                    status: ((Math.random() * 100) + 10) % 2 === 0 ? 'ACTIVE' : 'USED',
                    belongsTo: 'Someone',
                    purchaseDate: purchaseDate,
                    expiryDate: expiry,
                    type: i % 2 === 0 ? 'DAILY': 'SINGLE USE',
                    routeInfo: [] as any[]
                };

                results.push(data)

            }
            // 👇️ only update state if component is mounted
            if (isMounted) {
                setTableData(results.map((row: any) => {
                    Object.keys(row).forEach(k => {
                        if (row[k] instanceof Date) {

                            row[k] = row[k].toLocaleString();
                        }
                    });

                    row.actions = (
                        <ButtonGroup gap={2}>
                            <Button leftIcon={<FcViewDetails />} colorScheme='teal' variant='solid' size='sm' borderRadius={'7px'}>
								Details
                            </Button>
                            <Button rightIcon={<BiQrScan />} colorScheme='blue' variant='outline' borderRadius={'7px'} size='sm'>
								Claim
                            </Button>
                        </ButtonGroup>
                    );

                    row.status  =(<Badge colorScheme={badgeColorScheme(row.status)}>{row.status}</Badge>)
					

                    return row;
                }));
            }
        }

        fetchData({pageInfo: {index: 0, size: 7}});

        return () => {
            isMounted = false;
        };
    }, [cookies, boxBg, brandColor, history]);

    return (
        <Box>
            <Tabs variant={'unstyled'}>
                <TabList>
                    <Tab _selected={{color: brandColor, bg: boxBg}}>Search</Tab>
                    <Tab _selected={{color: brandColor, bg: boxBg}}>Scan</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <OperationsFilter fields={[]} onChange={function (e: any): void {
                            throw new Error("Function not implemented.");
                        } } children={undefined} />
                        <ComplexTable columnsData={columns} tableData={tableData} />
                    </TabPanel>
                    <TabPanel>
                        <TicketScan />
                    </TabPanel>
                </TabPanels>
            </Tabs>
			
        </Box>

    );
}

