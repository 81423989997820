import {RouteData, RoutePropTypes, RouteTableData} from "../models";
import React, {useEffect, useState} from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import IconBox from "../../../../components/icons/IconBox";
import {MdAssistantNavigation, MdLocationSearching, MdNavigation, MdSearch} from "react-icons/md";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export const RoutesTable = (props: RoutePropTypes) => {
  const {data, onRouteSelected, onStationSelected} = props;
  const [tableData, setTableData] = useState([] as RouteTableData[]);
  const boxBg = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

  useEffect(() => {
    const arr: RouteTableData[] = [];
    const groupedData: Map<number, RouteData[]> = data.reduce((entryMap, e) => entryMap.set(
            e.routeId, [...entryMap.get(e.routeId) || [], e]
        ), new Map()
    );

    groupedData.forEach((value: RouteData[], routeId: number) => {
      arr.push({routeId, stations: value});
    });
    console.log(arr)
    setTableData(arr);
  }, [props]);

  const selectRoute = (route: RouteTableData) => {
    let i = tableData.indexOf(route);
    route.selected = !route.selected;
    tableData[i] = route;
    setTableData([...tableData]);
    onRouteSelected(tableData[i]);
  }


  return (
      <Box border={'1px solid #E2E8F0'} borderRadius={'5px'} p={'1rem'}
           style={{overflow: 'auto'}}>

        <InputGroup size='sm'>
          <Input
              pr='4.5rem'
              type={'search'}
              placeholder='Search Route'
          />
          <InputRightElement width='4.5rem'>
            <IconBox

                icon={<Icon fontSize={'20px'} as={MdSearch}/>}
            />
          </InputRightElement>
        </InputGroup>
        <Accordion allowToggle={true}>
          {tableData
          .filter((route: RouteTableData) => route.stations.length > 0)
          .map((route: RouteTableData) => {
            const routeData = route.stations[0];
            return (
                <AccordionItem key={route.routeId}>
                  <h2>
                    <AccordionButton onClick={() => selectRoute(route)}>
                      <Flex width={'100%'}>
                        <Box display={'block'} textAlign='left' w={'85%'}>
                          <Text size={'sm'}
                                textDecoration={'underline'}><strong>{routeData.routeName}</strong></Text>
                          <Flex justifyContent={'space-between'}>
                            <Text color={'gray'} mr={5}>{routeData.providerName}: </Text>
                            <Text color={'gray'}>{routeData.lineName}</Text>
                            <Spacer/>
                            <Badge colorScheme={'green'}>
                              <FontAwesomeIcon
                                  size={'lg'} style={{marginRight: '12px'}}
                                  icon={routeData.transitType.icon.split(',') as IconProp}/>
                              {routeData.transitType.name}
                            </Badge>
                          </Flex>
                        </Box>
                        <Spacer/>
                        <Box>
                          <IconBox
                              w='46px'
                              h='46px'
                              bg={boxBg}
                              onClick={() => onRouteSelected(routeData)}
                              icon={<Icon fontSize={'18px'}
                                          as={!route.selected ? MdAssistantNavigation : MdLocationSearching}/>}
                          />
                        </Box>

                      </Flex>
                    </AccordionButton>

                  </h2>
                  <AccordionPanel pb={4}>

                    <TableContainer>
                      <Table variant='striped' size={'sm'} border={'thin'}>
                        <Tbody>
                          {
                            route.stations.map((stop: RouteData, i: number) => (
                                <Tr key={i}>
                                  <Td width={'50px'}>
                                    <IconBox
                                        w='46px'
                                        h='46px'
                                        bg={boxBg}
                                        onClick={() => onStationSelected(stop)}
                                        icon={<Icon fontSize={'18px'}
                                                    as={MdNavigation}/>}
                                    />
                                  </Td>
                                  <Td overflow={'hidden'} maxWidth={'200px !important'}
                                      textOverflow={'ellipsis'}>
                                    <Tooltip label={((stop.previousStop?.name?.concat(' - ') || '')
                                        + stop.currentStop?.name
                                        + (stop.nextStop ? (' - ' + stop.nextStop.name) : ''))}>
                                      <strong>{stop.currentStop?.name} - {stop.nextStop?.name}</strong>
                                    </Tooltip>
                                  </Td>
                                  <Td><Badge
                                      colorScheme={'yellow'}>ETA: {stop.eta}</Badge></Td>
                                </Tr>
                            ))
                          }
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </AccordionPanel>
                </AccordionItem>
            )
          })}
        </Accordion>
      </Box>
  )
};