import React, {useEffect, useState} from "react";
import {Box, Button, Flex, FormControl} from "@chakra-ui/react";
import {HamburgerIcon} from "@chakra-ui/icons";
import {AsyncSelect, chakraComponents} from "chakra-react-select";

export const RegionFilters = (props: any) => {

  const {data, filters, isFilterVisible, setFilterVisibility, onChange} = props;
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    setCountries(data.countries.map(filterUtil.mapLabel));
    setProvinces(data.provinces.map(filterUtil.mapLabel));
    setRegions(data.regions.map(filterUtil.mapValue));

  }, [data])

  return (
      <Box>
        <Flex flexDirection={'row'} my='10px'>
          <Button leftIcon={<HamburgerIcon/>} colorScheme='blue'
                  variant='outline'
                  padding={'10px'}
                  margin={'10px'}
                  onClick={() => setFilterVisibility(!isFilterVisible)}
          >
            Apply Filters
          </Button>

          <Flex flexDirection={'row'} my='10px'
                style={{
                  transition: 'display 0.6s ease-in-out',
                  display: isFilterVisible ? '' : 'none'
                }}
          >
            <FilterFormControl
                name="countries"
                placeholder="Countries"
                options={countries}
                filters={filters}
                onChange={(e: { value: any; }) => {
                  filters.country = e?.value;
                  filters.province = '';
                  filters.region = '';
                  filters.regionId = null;
                  onChange(filters);
                }}
            />
            <FilterFormControl
                name="provinces"
                placeholder="provinces"
                options={provinces}
                filters={filters}
                onChange={(e: { value: any; }) => {
                  filters.province = e?.value;
                  filters.region = '';
                  filters.regionId = null;
                  onChange(filters);
                }}
            />
            <FilterFormControl
                name="regions"
                placeholder="regions"
                options={regions}
                filters={filters}
                onChange={(e: { value: any; label: any; }) => {
                  filters.regionId = e?.value;
                  filters.region = e?.label;
                  onChange(filters);
                }}
            />
          </Flex>
        </Flex>
      </Box>

  )
}

const FilterFormControl = (props: any) => {
  const {name, placeholder, options, filters, onChange} = props;

  return (
      <FormControl w={'12rem'} mr={'1rem'}>
        <AsyncSelect
            isClearable={true}
            isMulti={false}
            name={name}
            placeholder={placeholder}
            tagVariant="subtle"
            components={asyncComponents}
            defaultOptions={options}
            value={options.find((option: any) => option.value === filters[name])}
            onChange={onChange}
        />
      </FormControl>
  )
}


const filterUtil = {
  mapLabel: (row: any) => {
    return {label: row.name, value: row.name}
  },
  mapValue: (row: any) => {
    return {label: row.name, value: (row.id === 0 ? row.name : row.id)}
  },
  addSelectAll: (rows: any[]) => {
    rows.unshift({label: 'Select All', value: ''});
    return rows;
  }
}

const asyncComponents = {
  LoadingIndicator: (props: any) => (
      <chakraComponents.LoadingIndicator
          color="currentColor"
          emptyColor="transparent"
          spinnerSize="md"
          speed="0.45s"
          thickness="2px"
          {...props}
      />
  ),
};


