/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


// Chakra imports
import { Box, Flex, useColorModeValue, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from '@chakra-ui/react';

// Custom components
import Banner from './components/Banner';

// Assets
import Tos from './components/Tos';
import PrivacyPolicy from "./components/PrivacyPolicy";
import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";


export default function TermsOfService() {
    const {tab} = useParams<{tab: string}>()
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const titleColor = useColorModeValue('gray.800', 'whiteAlpha');
    const [activeTab, setActiveTab] = React.useState(0);
    const handleTabsChange = (index: number) => {
        setActiveTab(index)
    }

    useEffect(() => {
        console.log(tab)
        if (tab === ('privacy-policy')) {
            setActiveTab(1);
        } else {
            setActiveTab(0);
        }
    }, [tab]);
    return (
        <Box>
            <Flex  flexDirection='column' gridArea={{ xl: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
                <Banner />
                <Flex direction='column'>

                    <Flex
                        mt='45px'
                        mb='20px'
                        justifyContent='space-between'
                        direction={{ base: 'column', md: 'row' }}
                        align={{ base: 'start', md: 'center' }}
                    >

                        <Tabs index={activeTab} onChange={handleTabsChange}>
                            <TabList mx='2rem'>
                                <Tab><Text color={titleColor} fontWeight={'bold'} fontSize={'lg'}>Terms and Conditions</Text></Tab>
                                <Tab><Text color={titleColor} fontWeight={'bold'} fontSize={'lg'}>Privacy Policy</Text></Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Tos />
                                </TabPanel>
                                <TabPanel>
                                    <PrivacyPolicy />
                                </TabPanel>

                            </TabPanels>
                        </Tabs>

                    </Flex>

                </Flex>
            </Flex>
        </Box>
    );
}
