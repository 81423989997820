// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import Information from 'views/admin/profile/components/Information';

// Assets
export default function TicketInformation(props: { [x: string]: any }) {
    const { ...rest } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
    const data: any = {
        code: 'EC5461SCE',
        status: ((Math.random() * 100) + 10) % 2 === 0 ? 'ACTIVE' : 'USED',
        belongsTo: 'Someone',
        purchaseDate: new Date().toLocaleString(),
        expiryDate: new Date().toLocaleString(),
        type: 'SINGLE USE',
    };

    const splitCase = (str: string) => {
        return str.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()
	  };

    return (
        <Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
            <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Ticket Information
            </Text>
			
            <SimpleGrid columns={2} gap='20px'>
                {
                    Object.keys(data).map((k, i) => {
                        return (
                            <Information key={'info_' + i} boxShadow={cardShadow} title={splitCase(k)} value={ data[k]} />
                        )
                    })
                }
                {/* <Information boxShadow={cardShadow} title='Education' value='Stanford University' />
				<Information boxShadow={cardShadow} title='Languages' value='English, Spanish, Italian' />
				<Information boxShadow={cardShadow} title='Department' value='Product Design' />
				<Information boxShadow={cardShadow} title='Work History' value='Google, Facebook' />
				<Information boxShadow={cardShadow} title='Organization' value='Simmmple Web LLC' />
				<Information boxShadow={cardShadow} title='Birthday' value='20 July 1986' /> */}
            </SimpleGrid>
        </Card>
    );
}
