// Chakra imports
import { Button, Flex, Link, Text } from '@chakra-ui/react';

// Assets
import banner from 'assets/img/operations/banner2.jpg';

export default function Banner() {
    // Chakra Color Mode

    const textStyles = {
        fontSize: '2.2em',
        color: 'white',
        fontWeight: 800,
        marginBottom: '40px',
        maxWidth: '72%',
        background: 'rgba(94,93,93,0.33)',
        padding: '1rem'
    };

    return (
        <Flex
            direction='column'
            bgImage={banner}
            bgSize='cover'
            py={{ base: '30px', md: '95px' }}
            px={{ base: '30px', md: '64px' }}
            borderRadius='30px'
            style={{
                backgroundColor: 'rgba(52, 52, 52, 0.8)',
                backgroundRepeat: 'no-repeat',
                filter: 'grayscale(40%)'
            }}
        >
            <Text
                fontSize='xl'
                color='var(--chakra-colors-secondaryGray-40)'
                fontWeight='800'
                mb='40px'
                style={textStyles}
            >
				Manage all your business operations easily and efficiently.
            </Text>
            <Flex align='center'>
                <Button
                    bg='white'
                    color='black'
                    _hover={{ bg: 'whiteAlpha.900' }}
                    _active={{ bg: 'white' }}
                    _focus={{ bg: 'white' }}
                    fontWeight='500'
                    fontSize='14px'
                    py='20px'
                    px='27'
                    me='38px'>
					Discover now!
                </Button>
                <Link>
                    <Text color='white' fontSize='lg' fontWeight='500'>
						Need help?
                    </Text>
                </Link>
            </Flex>
        </Flex>
    );
}
