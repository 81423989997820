// Chakra imports

import React, {useEffect} from "react";
import ComplexTable from 'views/admin/default/components/ComplexTable';

import axios from "axios";
import {
    Box, Badge, Button, ButtonGroup, Flex, Icon, useColorModeValue, Input, InputGroup, InputRightElement, Select, Spacer, Heading
} from '@chakra-ui/react';
import {useAuthHeader, useSignOut} from "react-auth-kit";
import {useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";
import { MdAltRoute, MdApps, MdArrowBack, MdArrowDropDown, MdBarChart, MdBuild, MdCreate, MdDirectionsBus, MdList, MdSchedule, MdSearch, MdTimeline } from "react-icons/md";
import {FaRoute} from 'react-icons/fa';
import IconBox from "components/icons/IconBox";
import RouteSchedules from "./RouteSchedules";
import {NavigationDataService} from "../../../../service/NavigationDataService";
import {initialiseMapFilters} from "../../map-overview/components/MapRoutes";
import { RouteData } from "views/admin/map-overview/models";



export default function RouteView() {

    const authHeader = useAuthHeader()
    const signOut = useSignOut();
    const history = useHistory();
    const [cookies] = useCookies(['active_provider']);

	

    const columns = [
        {
            Header: "Region",
            accessor: "region",
        },
        {
            Header: "Route",
            accessor: "name",
        },	
        {
            Header: "Stops / Stations",
            accessor: "stationCount",
        },	
        {
            Header: "",
            accessor: "actions",
        }
    ];

    const [tableData, setTableData] = React.useState([]);

    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'white');
    const [selectedRoute, setSelectedRoute] = React.useState(null)

    useEffect(() => {
        let isMounted = true;

        const result = {data: {data: [
            {
                region: 'Johannesburg Metropolitian',
                name: 'North to South',
                activeTrips: 14,
                totalTrips: 73,
                stationCount: 7,					
            }
        ]}}

      const searchFilters: any = {...initialiseMapFilters(), providerId: cookies.active_provider};
      searchFilters.providerIds = [searchFilters.providerId];

      NavigationDataService
      .getRoutes(searchFilters)
      .then((response) => {

        const groupedData: Map<number, RouteData[]> = response.data.reduce(
            (entryMap: any, e: any) => entryMap.set(
                e.routeId, [...entryMap.get(e.routeId)||[], e]
            ), new Map()
        );

        setTableData([...groupedData.values()].map((value: RouteData[]) => {
            const route = value[0];

            const data: any = {};
            data.actions = (
                <ButtonGroup gap={2}>
                  <Button leftIcon={<MdBuild />} colorScheme='teal' variant='solid' size='sm' borderRadius={'7px'}>
                    Settings
                  </Button>
                  <Button
                      rightIcon={<MdSchedule />}
                      colorScheme='blue'
                      variant='outline'
                      borderRadius={'7px'}
                      size='sm'
                      onClick={() => setSelectedRoute({routeInfo: route, action: 'SCHEDULE'})}
                  >
                    Schedule
                  </Button>
                </ButtonGroup>
            );

            data.stationCount = (
                <Flex>
                  <IconBox
                      w='24px'
                      h='24px'
                      mr='1rem'
                      bg={boxBg}
                      icon={<Icon w='18px' h='18px' as={MdAltRoute} color={brandColor} />}
                  />
                  {value.length} Stops
                </Flex>
            )

          data.region = (<>{route.regionName}</>)
          data.name = (<>{route.routeName}</>)

            return data;

        }));

      })
      .catch(console.error);

    }, []);

    const routeFilters = () => {

        const searchInput = () => {
            return (
                <Box p='1'>
                    <InputGroup>
                        <InputRightElement
                            pointerEvents='none'
                            children={
                                <Icon as={MdSearch} width='20px' height='20px' color='inherit' />
                            }
                        />
                        <Input type='Search' placeholder='Search Route' />
                    </InputGroup>
                </Box>
            );
        };
	
        return (
            <Flex alignItems='center' gap='2' wrap='wrap'>
                {searchInput()}
	
                <Spacer />
                <ButtonGroup gap='2'>
                    <Button colorScheme='teal'>
                        <Icon as={MdApps} width='20px' height='20px' color='inherit' />
                    </Button>
                    <Button colorScheme='teal'><Icon as={MdList} width='20px' height='20px' color='inherit' /></Button>
                </ButtonGroup>
            </Flex>
        );
		
    };

    return !selectedRoute 
        ? (
            <Box>
                {routeFilters()}
                <ComplexTable columnsData={columns} tableData={tableData} />
            </Box>

        ) 
        : (
            <Box>
                <Flex wrap={'wrap'} gap={4}>
                    <Button colorScheme='blue' mr={'2rem'} onClick={() => setSelectedRoute(undefined)}>
                        <Icon as={MdArrowBack} width='20px' height='20px' color='inherit' />
                    </Button>
		
                    <Heading as={'h4'}>
                        {selectedRoute.routeInfo.routeName}
                    </Heading>	
                    <Spacer />
                    <IconBox
                        w='52px'
                        h='52px'
                        mr='1rem'
                        bg={boxBg}
                        icon={<Icon w='35px' h='35px' as={FaRoute} color={brandColor} />}
                    />
                </Flex>
                <RouteSchedules route={selectedRoute.routeInfo} />
            </Box>
        );
}