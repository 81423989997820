import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import {AuthProvider} from "react-auth-kit";

import { createRoot } from 'react-dom/client';
import {library} from "@fortawesome/fontawesome-svg-core";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import Public from "./layouts/public";
const container = document.getElementById('root');
const root = createRoot(container);

library.add(fab, fas, far)

root.render(
    <ChakraProvider theme={theme}>
        <React.StrictMode>
            <AuthProvider
                authType = {'cookie'}
                authName={'_auth'}
                cookieDomain={window.location.hostname}
                cookieSecure={window.location.protocol === "https:"}
            >
                <BrowserRouter>
                    <Switch>
                        <Route path={`/auth`} component={AuthLayout} />
                        <Route path={`/terms-of-service/:tab`} component={Public} />
                        <Route path={`/admin`} component={AdminLayout} />
                        <Redirect from='/terms-of-service' to='/terms-of-service/terms-and-conditions' />
                        <Redirect from='/' to='/admin' />
                    </Switch>
                </BrowserRouter>
            </AuthProvider>

        </React.StrictMode>
    </ChakraProvider>
);