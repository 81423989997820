// Chakra imports
import { Button, Flex, Link, Text } from '@chakra-ui/react';

// Assets
import banner from 'assets/img/customer-satisfaction/banner.png';

export default function Banner() {
    // Chakra Color Mode

    const textStyles = {
        fontSize: '2.2em',
        color: 'white',
        fontWeight: 800,
        marginBottom: '40px',
        maxWidth: '72%',
        background: 'rgba(94,93,93,0.33)',
        padding: '1rem'
    };

    return (
        <Flex
            direction='column'
            bgImage={banner}
            bgSize='cover'
            py={{ base: '30px', md: '95px' }}
            px={{ base: '30px', md: '64px' }}
            borderRadius='30px'
            style={{
                backgroundRepeat: 'no-repeat',
                minHeight: '20vh'
            }}
        >
			
        </Flex>
    );
}
