/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/illustration1.jpg";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";
import {useAuthHeader, useSignIn, useSignOut} from "react-auth-kit";
import {useCookies} from "react-cookie";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  let isFormTouched = false;
  const authHeader = useAuthHeader()
  const signOut = useSignOut();
  const signIn = useSignIn();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['active_provider']);
  const [email, setEmail ] = React.useState('');
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    isFormTouched = true;
  }

  const [pwd, setPwd] = React.useState('');
  const handlePwdChange = (e: any) => setPwd(e.target.value);

  const isformValid = () => {
      return email && email !== '' && pwd && pwd !== '';
  };
  const [authFailed, setAuthFailed] = useState(false);

  const onFormSubmit = () => {


    const authPath = `/oauth/realms/commute-za/protocol/openid-connect/token`;
    const params = new URLSearchParams();
    params.append('username', email)
    params.append('password', pwd)
    params.append('grant_type', 'password')
    params.append('client_secret', '0e0b0b5b-1b5a-4b5a-8b5a-0b5a0b5a0b5a')
    params.append('client_id', 'commute-za-admin');

    axios.post(authPath, params).then(res => {


      if (res?.data?.access_token) {
        const getUserInfo = () => {
          return axios
              .get('/api/v1/account/info', {
                headers: {
                  Authorization: `${res.data.token_type} ${res.data.access_token}`
                }
              })
        };
        
        if (signIn({
          token: res.data.access_token,
          expiresIn:res.data.expires_in,
          tokenType: res.data.token_type
        })) {

          configureAxios(
            `${res.data.token_type} ${res.data.access_token}`,
            signOut,
            history
          );

          getUserInfo().then(e => {
            if (e.data.linkedOrganizations.length > 0) {
              signIn({
                token: res.data.access_token,
                expiresIn:res.data.expires_in,
                tokenType: res.data.token_type,
                authState: e.data,
              });

              if (!cookies['active_provider']) {
                setCookie('active_provider', e.data.linkedOrganizations[0].id, {path: '/'})                
              }
              
            }
  
            history.push('/admin');
          }).catch(() => setAuthFailed(true))

        } else {
          setAuthFailed(true)
        }

        

      } else {
        setAuthFailed(true);
      }

    })
  }

  


  
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          {!authFailed ||!isFormTouched ? (
              <Text
                  mb='36px'
                  ms='4px'
                  color={textColorSecondary}
                  fontWeight='400'
                  fontSize='md'
              >
                Enter your email and password to sign in!
              </Text>
          ) : (
              <FormErrorMessage>Email is required.</FormErrorMessage>
          )}

        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              value={email}
              onChange={handleEmailChange}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='your@email.com'
              mb='24px'
              fontWeight='500'
              size='lg'
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Enter password'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                value={pwd}
                onChange={handlePwdChange}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              disabled={(isFormTouched && !isformValid())}
              onClick={onFormSubmit}
            >
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export const configureAxios = (authHeader: string, signOut: any, history: any) => {
  axios.interceptors.request.use(
    config => {
        if (authHeader) {
            config.headers['Authorization'] = authHeader
        }
        return config
    },
    error => {
        Promise.reject(error).then(console.error)
    }
  );

  axios.interceptors.response.use(
      response => response,
      function (error) {

          if (
              error.response.status === 401
          ) {
              signOut();
              history.push('/auth');
              window.location.reload();
              return Promise.reject(error)
          }

          return Promise.reject(error)
      }
  );
}

export default SignIn;
