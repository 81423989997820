// Chakra imports

import {useEffect, useState} from "react";
import ComplexTable from 'views/admin/default/components/ComplexTable';

import axios from "axios";
import {
    Box, Icon, useColorModeValue, Flex, ButtonGroup, Button, Badge
} from '@chakra-ui/react';
import {useAuthHeader, useSignOut} from "react-auth-kit";
import {useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";
import IconBox from "components/icons/IconBox";
import { MdCreateNewFolder, MdDirectionsBus, MdList, MdNavigateNext } from "react-icons/md";
import OperationsFilter, { FilterField } from "./Filter";
import { configureAxios } from "views/auth/signIn";
import VehicleInfo from "./VehicleInfo";
import { CgDetailsMore } from "react-icons/cg";



export default function FleetView() {

    const authHeader = useAuthHeader()
    const signOut = useSignOut();
    const history = useHistory();
    const [cookies] = useCookies(['active_provider']);

    const [tableData, setTableData] = useState([]);
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'white');

    const [selectedVehicle, setSelectedVehicle] = useState(null);

	
    configureAxios(
        authHeader(),
        signOut,
        history
    );

    useEffect(() => {
        let isMounted = true;

        async function fetchData(filters: any) {

            const result = await axios
                .post(
                    `/api/v1/operations/${cookies.active_provider}/fleet`, filters
                )

            // 👇️ only update state if component is mounted
            if (isMounted) {
                console.log(result)
                setTableData(
                    result.data.map((row: any) => tableConfig.mapData(
                        row, history, boxBg, brandColor,
                        (e: any) => {
                            setSelectedVehicle(e);
                        },
                        (e: any) => {
                            history.push('/admin/street-view')
                        },
                    ))
                );
            }
        }

        fetchData({pageInfo: {index: 0, size: 7}});

        return () => {
            isMounted = false;
        };
    }, [cookies, boxBg, brandColor, history]);

    return (
        <Box>
            <OperationsFilter 
                fields={tableConfig.filters} 
                onChange={(e: any) => {}}
                children={
                    <ButtonGroup gap='2'>
                        <Button colorScheme='teal'>
                            <Icon as={MdCreateNewFolder} width='20px' height='20px' color='inherit' />
                        </Button>
                        <Button colorScheme='teal'><Icon as={MdList} width='20px' height='20px' color='inherit' /></Button>
                    </ButtonGroup>
                } />

			
            {
                selectedVehicle 
                    ? (<VehicleInfo vehicle={selectedVehicle} />)
                    : (<ComplexTable columnsData={tableConfig.columns} tableData={tableData} />)
            }
			
        </Box>

    );
}

const tableConfig = {
    columns: [
        {
            Header: "Fleet No",
            accessor: "fleetNo",
        },
        {
            Header: "Make",
            accessor: "make",
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "",
            accessor: "actions",
        }
    ],
    filters: [
        {
            key: 'vehicle_search',
            type: 'SEACH_INPUT',
            placeholder: 'Search Fleet'
        } as FilterField,
        {
            key: 'region_select',
            type: 'SELECT',
            placeholder: 'Select Region',
            options: [
                {label: 'Gauteng', 'value': 'GP'},
                {label: 'Western Cape', 'value': 'WC'},
                {label: 'Kwa-zulu Natal', 'value': 'KZN'},
            ]
        } as FilterField,
	


    ],
    mapData: (row: any, history: any, boxBg: any, brandColor: any, onSettingsClicked: any, onLocateClicked: any) => {
		
        row.fleetNo = (
            <Flex onClick={() => history.push('/admin/street-view')} style={{cursor: 'pointer'}}>
                <IconBox
                    w='24px'
                    h='24px'
                    mr='1rem'
                    bg={boxBg}
                    icon={<Icon w='18px' h='18px' as={MdDirectionsBus} color={brandColor} />}
                />
                {row.fleetNo.toUpperCase()}
            </Flex>	
			
        );

        row.make = `${row.make} ${row.model}`;
        row.actions = (
            <ButtonGroup gap={2}>
                <Button onClick={() => onSettingsClicked(row)} leftIcon={<CgDetailsMore />} colorScheme='teal' variant='solid' size='sm' borderRadius={'7px'}>
					Details
                </Button>
                <Button onClick={() => onLocateClicked(row)} rightIcon={<MdNavigateNext />} colorScheme='blue' variant='outline' borderRadius={'7px'} size='sm'>
					Locate
                </Button>
            </ButtonGroup>
        );

        row.status  =(<Badge colorScheme={badgeColorScheme(row.status)}>{row.status}</Badge>)
		

        return row;
		
    }
}

const badgeColorScheme = (status: string) => {
    switch (status?.toUpperCase()) {
        case 'PARKED': return 'yellow';
        case 'ACTIVE': return 'green';
        case 'INACTIVE': return 'red';
        default: return 'gray';
    }
}