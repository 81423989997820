// Chakra imports

import {useState} from "react";

import axios from "axios";
import {
    Box, Icon, useColorModeValue, ButtonGroup, Button, Grid, GridItem, Input, InputGroup, InputRightElement, Center, Tab, TabList, TabPanel, TabPanels, Tabs
} from '@chakra-ui/react';
import {useAuthHeader, useSignOut} from "react-auth-kit";
import {useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";
import IconBox from "components/icons/IconBox";
import { MdClose, MdSearch } from "react-icons/md";
import {BiQrScan} from  'react-icons/bi';
import Card from "components/card/Card";
// import { QrReader } from "react-qr-reader";
import TicketInformation from "./TicketInformation";
import TripOverview from "./TripOverview";
import { createTheme, ThemeProvider } from "@mui/material";



export default function TicketScan() {

    const authHeader = useAuthHeader()
    const signOut = useSignOut();
    const history = useHistory();
    const [cookies] = useCookies(['active_provider']);
	

    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'white');

    const [isReaderActive, setReaderState] = useState(false);

    return (
        <Grid
            templateColumns='repeat(5, 1fr)'
            gap={4}
        >
            <GridItem colSpan={2}>
			
                <Card>

                    <Box py='3'>
                        <InputGroup>
                            <InputRightElement
                                pointerEvents='none'
                                children={
                                    <Icon as={MdSearch} width='20px' height='20px' color='inherit' />
                                }
                            />
                            <Input type='Search' placeholder='Search' />
                        </InputGroup>
                    </Box>

                    {!isReaderActive 
                        ? 
                        (<Box h={'30vh'} w={'100%'} bg={boxBg}>
                            <Icon w={'100%'} h={'100%'} as={BiQrScan} color={brandColor} onClick={() => {
                                setReaderState(true);
                            }}/>
								
                        </Box>)
                        : 
                        (<Box w={'80%'} bg={boxBg}>
								
								
							
                            {/*<QrReader*/}
                            {/*    containerStyle={{margin: '1rem'}}*/}
                            {/*    onResult={(result, error) => {*/}


                            {/*        if (result) {*/}
                            {/*            console.log(result);*/}
                            {/*            setReaderState(false);*/}
                            {/*        }*/}

                            {/*    } } */}
                            {/*    constraints={{ facingMode: 'user' }}	*/}
                            {/*/>*/}
                            <Center>
                                <ButtonGroup gap={2} m={'1rem'}>
                                    <Button
                                        colorScheme={'red'}
                                        onClick={() => setReaderState(false)}
                                        leftIcon={<Icon as={MdClose} />}
                                    >Cancel</Button>
                                </ButtonGroup>
                            </Center>
								
								
                        </Box>)
				
                    }
					

                </Card>
            </GridItem>
            <GridItem colSpan={3}>
                <Tabs>
                    <TabList>						
                        <Tab>Ticket Details</Tab>
                        <Tab>Trip Details</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <TicketInformation minH='365px' />
                        </TabPanel>
                        <TabPanel>
                            <ThemeProvider theme={createTheme()}>
                                <TripOverview />
                            </ThemeProvider>							
                        </TabPanel>
                    </TabPanels>
                </Tabs>
				
            </GridItem>
			
        </Grid>

    );
}

