// Chakra imports
import { Box, Flex, Heading, Icon, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import IconBox from 'components/icons/IconBox';
// Assets
import { MdThumbDownAlt, MdThumbsUpDown, MdThumbUpAlt } from 'react-icons/md';

export default function Tos() {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.600';
    const brandColor = useColorModeValue('brand.500', 'white');
    const bg = useColorModeValue('white', 'navy.700');

    const info = `
        Terms and Conditions Template for Mobile Apps
        Please read these terms and conditions ("terms and conditions", "terms") carefully before using Commute ZA mobile application (“app”, "service") operated by Melotech Systems ("us", 'we", "our").
    
        Conditions of Use
        By using this app, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to stop using the app accordingly. Melotech Systems only grants use and access of this app, its products, and its services to those who have accepted its terms.
    
        Privacy Policy
        Before you continue using our app, we advise you to read our privacy policy regarding our user data collection. It will help you better understand our practices. The policy can be accessed here.
    
        Age Restriction
        You must be at least 18 (eighteen) years of age before you can use this app. By using this app, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. Melotech Systems assumes no responsibility for liabilities related to age misrepresentation.
    
        Intellectual Property
        You agree that all materials, products, and services provided on this app are the property of Melotech Systems, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the Melotech Systems’s intellectual property in any way, including electronic, digital, or new trademark registrations.
    
        You grant Melotech Systems a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.
    
        User Accounts
        As a user of this app, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.
    
        If you think there are any possible issues regarding the security of your account on the app, inform us immediately so we may address them accordingly.
    
        We reserve all rights to terminate accounts, edit or remove content and cancel orders at our sole discretion.
    
        Applicable Law
        By using this app, you agree that the laws of South Africa, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between Melotech Systems and you, or its business partners and associates.
    
        Disputes
        Any dispute related in any way to your use of this app or to products you purchase from us shall be arbitrated by state or federal court in South Africa and you consent to exclusive jurisdiction and venue of such courts.
    
        Indemnification
        You agree to indemnify Melotech Systems and its affiliates and hold Melotech Systems harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.
    
        Limitation on Liability
        Melotech Systems is not liable for any damages that may occur to you as a result of your misuse of our app.
    
        Melotech Systems reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between Melotech Systems and the user, and this supersedes and replaces all prior agreements regarding the use of this app.
    `;

    return (
        <Card bg={bg} p='14px' m={4}>
            <Box p={4}>
                <Flex direction="column" align="center">
                    <Heading as="h2" size="xl" mb={4} color={textColorPrimary}>
                        Terms and conditions
                    </Heading>
                    <Text fontSize="lg" color={textColorSecondary} mb={6} style={{ whiteSpace: 'pre-line', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        {info}
                    </Text>
                    <Flex>
                        <IconBox as="button" icon={<Icon as={MdThumbUpAlt} w={6} h={6} mr={4} />} />
                        <Spacer />
                        <IconBox as="button" icon={<Icon as={MdThumbDownAlt} w={6} h={6} />} />
                    </Flex>
                </Flex>
            </Box>
        </Card>
    );
}
