import { Icon } from '@chakra-ui/react';
import {MdHome, MdApps, MdAltRoute, MdSupportAgent, MdAdminPanelSettings} from 'react-icons/md';

// Admin Imports
import MapOverview from 'views/admin/map-overview';
import MainDashboard from 'views/admin/default';
import Operations from 'views/admin/operations';
import Profile from 'views/admin/profile';
import CustomerSatisfaction from 'views/admin/customer-satisfaction';

const routes = [
    {
        name: 'Operations',
        layout: '/admin',
        path: '/operations',
        icon: <Icon as={MdApps} width='20px' height='20px' color='inherit' />,
        component: Operations,
        secondary: true
    },
    {
        name: 'Street Overview',
        layout: '/admin',
        path: '/street-view',
        icon: <Icon as={MdAltRoute} width='20px' height='20px' color='inherit' />,
        component: MapOverview
    },
    {
        name: 'Customer Satisfaction',
        layout: '/admin',
        path: '/customer-satisfaction',
        icon: <Icon as={MdSupportAgent} width='20px' height='20px' color='inherit' />,
        component: CustomerSatisfaction,
        secondary: true
    },
    {
        name: 'Reports',
        layout: '/admin',
        path: '/reports',
        icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
        component: MainDashboard
    },
    {
        name: 'Administration',
        layout: '/admin',
        path: '/settings',
        icon: <Icon as={MdAdminPanelSettings} width='20px' height='20px' color='inherit' />,
        component: Profile,
        secondary: true
    },
    {
        name: 'Documents',
        layout: '/admin',
        path: '/terms-of-service',
        icon: <Icon as={MdAdminPanelSettings} width='20px' height='20px' color='inherit' />,
        component: Profile,
        secondary: true
    }
];

export default routes;
