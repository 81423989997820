// Chakra imports


import axios from "axios";
import {
    Box, Button, ButtonGroup, Flex, Icon, useColorModeValue, Input, InputGroup, InputRightElement, Spacer, SimpleGrid, Text
} from '@chakra-ui/react';
import {useAuthHeader, useSignOut} from "react-auth-kit";
import {useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";
import { MdSearch, MdApps, MdList, MdCreate } from "react-icons/md";
import NFT from 'components/card/NFT';

import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import Nft4 from 'assets/img/nfts/Nft4.png';
import Nft5 from 'assets/img/nfts/Nft5.png';
import Nft6 from 'assets/img/nfts/Nft6.png';



export default function DriverView() {

    const authHeader = useAuthHeader()
    const signOut = useSignOut();
    const history = useHistory();
    const [cookies] = useCookies(['active_provider']);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const textColorBrandActive = useColorModeValue('secondaryGray.500', 'white');

	


    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'white');

    const drivers = [];

    for (let i = 0; i < 7; i++) {

        drivers.push(
            {
                image: "http://graph.facebook.com/v2.5/6727/picture?height=200&height=200",
                name: 'Driver ' + (i + 1),
                region: 'Some region',
                ratings: [Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1],
                tripHrs: Math.round(Math.random() * 100000),
                status: Math.round(Math.random()) % 2 === 0 ? 'ACTIVE' : 'INACTIVE',
                contactInfo: '+27680861024'
            }
        )
    }

    const routeFilters = () => {

        const searchInput = () => {
            return (
                <Box p='1'>
                    <InputGroup>
                        <InputRightElement
                            pointerEvents='none'
                            children={
                                <Icon as={MdSearch} width='20px' height='20px' color='inherit' />
                            }
                        />
                        <Input type='Search' placeholder='Search Driver' />
                    </InputGroup>
                </Box>
            );
        };
	
        return (
            <Flex alignItems='center' gap='2' wrap='wrap' mb={'1rem'}>
                {searchInput()}
	
                <Spacer />
                <ButtonGroup gap='2'>
                    <Button colorScheme='teal' leftIcon={<Icon as={MdCreate} width='20px' height='20px' color='inherit' />}>Add Driver</Button>
                </ButtonGroup>				
            </Flex>
        );
		
    };

    return (
        <Box>
            {routeFilters()}
            <hr />
            <SimpleGrid 
                columns={{ base: 1, md: 3 }} 
                gap='20px' mb={{ base: '20px', xl: '0px' }} 
                mt={'2rem'}
            >

                {
                    drivers.map((d, i) => (
                        <NFT
                            key={'nft_' + i}
                            name={d.name}
                            image={d.image}
                            region={d.region}
                            ratings={d.ratings}
                            tripHrs={d.tripHrs}
                            status={d.status}
                            contactInfo={d.contactInfo}
                        />
                    ))
                }				
            </SimpleGrid>
        </Box>
		
    )
}