
import {
    Box,
    Icon, Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,


} from "@chakra-ui/react";
import {
    MdAssistantNavigation,
    MdTaxiAlert,
} from "react-icons/md";
import ITraffic from "./components/ITraffic";
import Stats from "./components/Stats";
import MapRoutes from "./components/MapRoutes";


export default function MapOverview()  {


    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Tabs isManual>
                <TabList>

                    <Tab>
                        <strong>Routes & Stations</strong>
                        <Icon as={MdAssistantNavigation} ml='0.5rem' fontSize={'20px'} />
                    </Tab>
                    <Tab>
                        <strong>i-Traffic Alerts</strong>
                        <Icon as={MdTaxiAlert} ml='0.5rem' fontSize={'20px'} />
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel >
                        {/*<Stats />*/}
                        <MapRoutes />
                    </TabPanel>
                    <TabPanel>
                        {/*<Stats />*/}
                        <ITraffic />
                    </TabPanel>

                </TabPanels>
            </Tabs>
        </Box>
    )
}


