import React, {useEffect} from "react";
import {NavigationDataService} from "../../../../service/NavigationDataService";
import {Button, HStack} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const TransportNetworkFilters = (props: any) => {
  const {data, filters, isFilterVisible, onChange} = props;

  useEffect(() => {
    NavigationDataService
    .getTransportModes()
    .then(data => {
      console.log(data);
      filters.transportModes = data.map((mode: any) => {
        return {...mode, selected: true}
      });
      onChange(filters);
    })
    .catch(console.error);
  }, [])

  return (
      <HStack
          style={{
            transition: 'display 0.6s',
            display: isFilterVisible ? '' : 'none'
          }}
      >
        {filters?.transportModes?.map((mode: any, i: number) => {
          return (
              <Button
                  key={i}
                  leftIcon={<FontAwesomeIcon icon={mode.icon.split(',')}/>}
                  colorScheme='blue'
                  fontSize={'14px'}
                  variant={mode.selected ? 'solid' : 'outline'}
                  padding={'20px'}
                  onClick={() => {
                    mode.selected = !mode.selected;
                    filters.transportModes[i] = mode;
                    onChange(filters);
                  }}
              >
                {mode.name}
              </Button>
          )
        })}
      </HStack>

  )
}