import { Button, Flex, Icon, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
import IconBox from 'components/icons/IconBox';
import { GrIntegration } from 'react-icons/gr';
import { MdWifiCalling3 } from 'react-icons/md';
import logo from '../../../assets/img/logo.png';

export default function SidebarDocs() {
    const bgColor = 'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)';
    const borderColor = useColorModeValue('white', 'navy.800');
    const brandColor = useColorModeValue('brand.500', 'white');

    return (
        <Flex
            justify='center'
            direction='column'
            align='center'
            bg={bgColor}
            borderRadius='30px'
            me={{ base: '20px' }}
            position='relative'>
            <Flex
                border='5px solid'
                borderColor={borderColor}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                borderRadius='50%'
                w='94px'
                h='94px'
                align='center'
                justify='center'
                mx='auto'
                position='absolute'
                left='50%'
                top='-47px'
                transform='translate(-50%, 0%)'>
                <IconBox
                    icon={<Icon w='40px' h='40px' as={GrIntegration} color={borderColor} />}
                />
                {/* <Image src={logo} w='40px' h='40px' /> */}
            </Flex>
            <Flex direction='column' mb='12px' align='center' justify='center' px='15px' pt='55px'>
                <Text
                    fontSize={{ base: 'lg', xl: '18px' }}
                    color='white'
                    fontWeight='bold'
                    lineHeight='150%'
                    textAlign='center'
                    px='10px'
                    mb='14px'>
					Upgrade to PRO
                </Text>
                <Text fontSize='14px' color={'white'} px='10px' mb='14px' textAlign='center'>
					Optimize your Public Transport operations with our Commute-ZA PRO
                </Text>
            </Flex>
            <Link href='#'>
                <Button
                    bg='whiteAlpha.300'
                    _hover={{ bg: 'whiteAlpha.200' }}
                    _active={{ bg: 'whiteAlpha.100' }}
                    mb={{ sm: '16px', xl: '24px' }}
                    color={'white'}
                    fontWeight='regular'
                    fontSize='sm'
                    minW='185px'
                    mx='auto'>
					Upgrade to PRO
                </Button>
            </Link>
        </Flex>
    );
}
