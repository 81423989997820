// Chakra imports
import {
    Box, Flex, Icon, Input, InputGroup, InputRightElement, Select, Spacer
} from '@chakra-ui/react';
import { Filter } from 'http-proxy-middleware';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import {MdArrowDropDown, MdSearch} from "react-icons/md";

export interface FilterField {
    key: any;
    type: 'SEACH_INPUT' | 'SELECT';
    icon?: IconType;
    placeholder?: string;
    options?: {label: string; value: string}[];
    value?: any;

}

export default function OperationsFilter(props: {fields: FilterField[]; onChange: (e: any) => void; children: any}) {

    const {fields, onChange, children} = props;


    const onFieldChanged = (filterKey: any, newValue: any) => {
        const filters = props.fields;

        filters[filters.indexOf(filters.find(f => f.key === filterKey))].value = newValue
        onChange(filters);

    }

    const searchInput = (field: FilterField) => {
        return (
            <Box p='1'>
                <InputGroup>
                    <InputRightElement
                        pointerEvents='none'
                        children={
                            <Icon as={field.icon || MdSearch} width='20px' height='20px' color='inherit' />
                        }
                    />
                    <Input 
                        type='Search' 
                        placeholder={field.placeholder || 'Search'}
                        value={field.value} 
                        onChange={(e) => onFieldChanged(field.key, e.target.value)}
                    />
                </InputGroup>
            </Box>
        );
    };

    const selectFilter = (field: FilterField) => {
        return (
            <Box p='1'>
                <Select placeholder={field.placeholder} icon={<MdArrowDropDown />} value={field.value} onChange={(e) => onFieldChanged(field.key, e.target.value)}>
                    {
                        field.options.map(option => 
                            <option 
                                value={option.value} 
                                key={option.label} 
                            > 
                                {option.label}
                            </option>
                        )
                    }
                </Select>
            </Box>
        );
    }

    const filterFields = useMemo(() => fields, [fields]);
    const content = useMemo(() => children, [children]);

    return (
        <Flex alignItems='center' gap='2' wrap='wrap'>
            {
                (filterFields || []).map((field, i) => (
                    <Box key={i}>
                        {field.type === 'SEACH_INPUT'
                            ? searchInput(field)
                            : selectFilter(field)
                        }
                    </Box>
                ))
            }

            <Spacer />
            {content}
        </Flex>
    );
}
