import axios, {AxiosResponse} from "axios";
import {RouteData} from "../views/admin/map-overview/models";
import {Schedule} from "../views/admin/operations/models";

export const NavigationDataService = {
    getNavigationFilterData: async (filters: any) => {
        const response = await
            axios.post(`/api/v1/navigation-service/filters`, filters);
        return response.data;
    },
    getTransportModes: async () => {
        const response = await
            axios.get(`/api/v1/navigation-service/transit-modes`);
        return response.data;
    },
    getRoutes: async (filters: any): Promise<AxiosResponse<RouteData[]>> => {
        return await
            axios.post('/api/v1/navigation-service/routes/stations', filters);
    },
    getRouteSchedules: async (routeId: number, rowNum: number): Promise<AxiosResponse<Schedule[]>> => {
        return await
            axios.get(`/api/v1/navigation-service/routes/${routeId}/schedules?rowNumber=${rowNum}`);
    }

}