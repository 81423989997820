/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


// Chakra imports
import { Box, Flex, GridItem, useColorModeValue, Icon, SimpleGrid, Text, Tab, TabList, TabPanel, TabPanels, Tabs, Button} from '@chakra-ui/react';
import IconBox from 'components/icons/IconBox';

// Custom components
import Banner from './components/Banner';

import Card from 'components/card/Card';

import {MdBarChart} from 'react-icons/md';

// Assets
import RecentActivity from '../operations/components/RecentActivity';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import MiniStatistics from 'components/card/MiniStatistics';
import Feedback from './components/Feedback';
import { IoMdTrash } from 'react-icons/io';
import ComplexTable from 'views/admin/default/components/ComplexTable';


export default function CustomerSatisfaction() {
    // Chakra Color Mode
    const sampleActivities = [
        {
		  "incident": ["severe","car accident"],
		  "date": "2018-10-01T12:34:56.789"
        },
        {
		  "incident": ["moderate","car breakdown"],
		  "date": "2018-10-01T12:34:56.789"
        },
        {
		  "incident": ["minor","flat tire"],
		  "date": "2018-10-01T12:34:56.789"
        },
        {
		  "incident": ["minor","running out of gas"],
		  "date": "2018-10-01T12:34:56.789"
        },
        {
		  "incident": ["minor","hitting a pothole"],
		  "date": "2018-10-01T12:34:56.789"
        }
    ];


    const sampleFeedback = [
        {
            title: 'Lost my phone on the bus',
            ranking: 0,
            comment: 'During a trip from Pretoria to town, my phone was snatched from my bag.',
            date: '2022-11-04 11:41'
        },
        {
            title: 'The driver was overspeeding',
            ranking: 0,
            comment: 'These drivers are worse than taxis! yooh, I thought I was gona die today!',
            date: '2022-10-28 17:12'
        },
        {
            title: 'Tx for the discounts',
            ranking: 1,
            comment: 'I was able to travel from work and back for free thanks to the promos. ',
            date: '2022-10-13 09:37'
        },
        {
            title: 'the lady by the window was so rude to me',
            ranking: 0,
            comment: 'I was trying to load points at park station. And this lady was just stuck on her phone. I almost missed my bus waiting for them to serve me.',
            date: '2022-10-06 13:16'
        }
    ];
    const columns = [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Details",
            accessor: "details",
        },	
        {
            Header: "Validity",
            accessor: "validity",
        },	
        {
            Header: "",
            accessor: "actions",
        }
    ];
    const data = [
        {
            name: '10% off Friday special',
            details: 'Get 10 % off when purchasing tickets on Fridays between 8 am and 5 pm',
            validity: 'Fridays between 8 am and 5 pm',
            actions: (
                <Button rightIcon={<IoMdTrash />} colorScheme='blue' variant='outline' borderRadius={'7px'} size='sm'>
					Delete
                </Button>
            )
        },
        {
            name: 'Buy 10 get 1 free',
            details: 'Get a free trip for every 10 tickets purchased',
            validity: '2022-08-11 to 2023-08-10',
            actions: (
                <Button rightIcon={<IoMdTrash />} colorScheme='blue' variant='outline' borderRadius={'7px'} size='sm'>
					Delete
                </Button>
            )
        },
        {
            name: 'Black friday special',
            details: 'Get up to 50 % off ticket sales',
            validity: 'Black Friday only',
            actions: (
                <Button rightIcon={<IoMdTrash />} colorScheme='blue' variant='outline' borderRadius={'7px'} size='sm'>
					Delete
                </Button>
            )
        },
        {
            name: 'Share the discounts',
            details: 'Get 25 free points for each friend who signs up.',
            validity: '2022-11-22 to 2022-12-01',
            actions: (
                <Button rightIcon={<IoMdTrash />} colorScheme='blue' variant='outline' borderRadius={'7px'} size='sm'>
					Delete
                </Button>
            )
        },
        {
            name: 'Green Monday Special',
            details: 'Get 50 free points for every ticket purchased on Mondays',
            validity: 'Every Monday between 8 am and 10 pm',
            actions: (
                <Button rightIcon={<IoMdTrash />} colorScheme='blue' variant='outline' borderRadius={'7px'} size='sm'>
					Delete
                </Button>
            )
        }
		
    ]
	
	  const columnsData = Object.keys(sampleActivities[0]).map(k => {
        return {
		  Header:  k.charAt(0).toUpperCase() + k.slice(1),
		  accessor: k
        }
	  });


    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            <SimpleGrid
                columns={{base: 2, xl: 3}}
                mb='20px'
                gap={{ base: '20px', xl: '20px' }}
                display={{ base: 'block', xl: 'grid' }}>
				
                <GridItem colSpan={2} mr={4}>
                    <Flex  flexDirection='column' gridArea={{ xl: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
                        <Banner />
                        <Flex direction='column'>
							
                            <Flex
                                mt='45px'
                                mb='20px'
                                justifyContent='space-between'
                                direction={{ base: 'column', md: 'row' }}
                                align={{ base: 'start', md: 'center' }}
                            >

                                <Tabs>
                                    <TabList mx='2rem'>
                                        <Tab>Campaigns & Promotions</Tab>
                                        <Tab>Customer Feedback</Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <ComplexTable columnsData={columns} tableData={data} />
                                        </TabPanel>
                                        <TabPanel>
                                            {
                                                sampleFeedback.map((feedback, i) => (
                                                    <Feedback 
                                                        key={i}
                                                        title={feedback.title} 
                                                        ranking={feedback.ranking}
                                                        comment={feedback.comment}
                                                        date={feedback.date}
                                                    />
                                                ))
                                            }
											
                                        </TabPanel>
										
                                    </TabPanels>
                                </Tabs>
								
                            </Flex>
							
                        </Flex>
                    </Flex>
                </GridItem>
                <GridItem colSpan={'auto'} mr={4}>

                    <Flex flexDirection='column' gap={'20px'} gridArea={{ xl: '1 / 3 / 2 / 4', '2xl': '1 / 2 / 2 / 3' }} style={{minWidth: '10rem'}}>
                        <Card px='0px' mb='20px'>
                            <RecentActivity tableData={sampleActivities} columnsData={columnsData} />
                        </Card>
                        <DailyTraffic />
						
                    </Flex>

                </GridItem>
				
            </SimpleGrid>
        </Box>
    );
}
