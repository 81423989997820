/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {useState} from 'react';

// Chakra imports
import {
    Box,
    Flex,
    GridItem,
    useColorModeValue,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Icon,
    SimpleGrid,
    Stack
} from '@chakra-ui/react';
import IconBox from 'components/icons/IconBox';

// Custom components
import Banner from 'views/admin/operations/components/Banner';

import Card from 'components/card/Card';

import {MdBarChart, MdDirectionsBus} from 'react-icons/md';
import {GiBusStop} from 'react-icons/gi';
import {ImTicket, ImUsers } from 'react-icons/im';

// Assets
import FleetView from "./components/FleetView";
import RouteView from './components/RouteView';
import DriverView from './components/DriverView';
import TicketingView from './components/TicketingView';
import RecentActivity from './components/RecentActivity';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import MiniStatistics from 'components/card/MiniStatistics';
import {configureAxios} from "../../auth/signIn";
import {useAuthHeader, useSignOut} from "react-auth-kit";
import {useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";

export default function Operations() {
    // Chakra Color Mode
    // TODO: - Remove sample data
    const sampleActivities = [
        {
		  "incident": ["severe","car accident"],
		  "date": "2018-10-01T12:34:56.789"
        },
        {
		  "incident": ["moderate","car breakdown"],
		  "date": "2018-10-01T12:34:56.789"
        },
        {
		  "incident": ["minor","flat tire"],
		  "date": "2018-10-01T12:34:56.789"
        },
        {
		  "incident": ["minor","running out of gas"],
		  "date": "2018-10-01T12:34:56.789"
        },
        {
		  "incident": ["minor","hitting a pothole"],
		  "date": "2018-10-01T12:34:56.789"
        }
	  ];
	
	  const columnsData = Object.keys(sampleActivities[0]).map(k => {
        return {
		  Header:  k.charAt(0).toUpperCase() + k.slice(1),
		  accessor: k
        }
	  });


    const textColorBrand = useColorModeValue('brand.500', 'white');
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const tabs = [
        {
            name: 'Route Schedules',
            icon: GiBusStop,
            component: () => (
                <Box>
                    <RouteView />
                </Box>
            )
        },
        {
            name: 'Ticketing', 
            icon: ImTicket,
            component: () => (
                <Box>
                    <TicketingView />
                </Box>
            )
        },
        {
            name: 'Fleet',
            icon: MdDirectionsBus,
            component: () => (
                <Box>
                    <FleetView />
                </Box>
            )
        },
        {
            name: 'Drivers', 
            icon: ImUsers,
            component: () => (
                <Box>
                    <DriverView />
                </Box>
            )
        }
    ];
    const [activeTab, setActiveTab] = useState(0);

    const authHeader = useAuthHeader()
    const signOut = useSignOut();
    const history = useHistory();
    const [cookies] = useCookies(['active_provider']);


    configureAxios(
        authHeader(),
        signOut,
        history
    );


    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {/* Main Fields */}
            <SimpleGrid
                columns={{base: 2, xl: 3}}
                mb='20px'
                // gridTemplateColumns={{ xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr' }}
                gap={{ base: '20px', xl: '20px' }}
                display={{ base: 'block', xl: 'grid' }}>
				
                <GridItem colSpan={2} mr={4}>
                    <Box  flexDirection='column' gridArea={{ xl: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
                        <Banner />
                        <Box>
						

                            <Flex
                                mt='45px'
                                mb='20px'
                                justifyContent='space-between'
                                direction={'column'}
                                align={{ base: 'start', md: 'center' }}
                                w='100%'
                            >
                                <Tabs w={'100%'} onChange={(index) => setActiveTab(index)}>
                                    <TabList mx='2rem'>
                                        {
                                            tabs
                                                .map((tab, index) =>
                                                    <Tab
                                                        key={'tab' + index}
                                                    >
                                                        <IconBox
                                                            mr={'1rem'}
                                                            icon={<Icon w='25px' h='25px' as={tab.icon} color={textColorBrand} />}
                                                        />
                                                        {tab.name}
                                                    </Tab>
                                                )
                                        }
                                    </TabList>
                                    <TabPanels>
                                        {
                                            tabs
                                                .map((tab, index) =>
                                                    <TabPanel
                                                        key={'tabpanel_' + index}
                                                    >
                                                        <Card mt={'2rem'}>
                                                            {tabs[activeTab].component()}
                                                        </Card>
														
                                                    </TabPanel>
                                                )
                                        }
                                    </TabPanels>
                                </Tabs>
								
                            </Flex>
							
                        </Box>
                    </Box>
                </GridItem>
                <GridItem colSpan={'auto'} mr={4}>

                    <Flex flexDirection='column' gap={'20px'} gridArea={{ xl: '1 / 3 / 2 / 4', '2xl': '1 / 2 / 2 / 3' }} style={{minWidth: '10rem'}}>
                        <Card px='0px' mb='20px'>
                            <RecentActivity tableData={sampleActivities} columnsData={columnsData} />
                        </Card>

                        <MiniStatistics
                            startContent={
                                <IconBox
                                    w='56px'
                                    h='56px'
                                    bg={boxBg}
                                    icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}
                                />
                            }
                            name='Sales Revenue'
                            value='R 350.4'
                        />
                        <DailyTraffic />
						
                    </Flex>

                </GridItem>
				
            </SimpleGrid>
            {/* Delete Product */}
        </Box>
    );
}
