import {Box, GridItem, Icon, SimpleGrid} from "@chakra-ui/react";
import React, {useCallback, useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import Card from "../../../../components/card/Card";
import MapContainer, { toLatLong } from "./MapContainer";
import {MdAssistantNavigation} from "react-icons/md";
import {useAuthHeader, useSignOut} from "react-auth-kit";
import {useHistory} from "react-router-dom";
import {configureAxios} from "views/auth/signIn";
import {NavigationDataService} from "../../../../service/NavigationDataService";
import {MapFilterData, MapFilters, RouteData} from "../models";
import {TransportNetworkFilters} from "./TransportNetworkFilters";
import {RegionFilters} from "./RegionFilters";
import {RoutesTable} from "./RoutesTable";

export default function MapRoutes() {
    const authHeader = useAuthHeader();
    const signOut = useSignOut();
    const history = useHistory();
    const [cookies] = useCookies(['active_provider']);
    const [mapRoutes, setMapRoutes] = useState<RouteData[]>([]);
    const [mapFilterData, setMapFilterData] = useState(initialiseMapFilterData);
    const [mapFilters, setMapFilters] = useState(initialiseMapFilters);
    const [mapCenter, setMapCenter] = useState({lat: -26.270760, lng: 28.112268});
    const [isFilterVisible, setFilterVisibility] = useState(false);

    configureAxios(
        authHeader(),
        signOut,
        history
    );

    const updateRoutes = useCallback(() => {
        const searchFilters: any = {...mapFilters, providerId: cookies.active_provider};
        searchFilters.transportModeIds = searchFilters.transportModes?.map((mode: any) => mode.id);
        searchFilters.providerIds = [searchFilters.providerId];

        NavigationDataService
        .getRoutes(searchFilters)
        .then((res) => setMapRoutes(res.data))
        .catch(console.error);

    }, [cookies, mapFilters]);

    const onFiltersChanged = React.useCallback((filters: MapFilters, callback?: ((arg: any) => void)) => {
        setMapFilters(filters);
        NavigationDataService
            .getNavigationFilterData(filters)
            .then(data => {
                setMapFilterData(data);
                if (callback) {
                    callback(data);
                }
                updateRoutes();
            })
            .catch(console.error);
        updateRoutes();
    }, [updateRoutes]);

    const onRouteSelected = useCallback((selectedRoute: RouteData) => {
        setMapRoutes((routes: RouteData[]) => {
            return routes.map(route => {
                if (route.routeId === selectedRoute.routeId) {
                    route.selected = !route.selected;
                }

                setMapCenter(toLatLong(route.currentStop));
                return route;
            })
        });
    }, []);

    const onStationSelected = useCallback((station: RouteData) => {
        setMapCenter(toLatLong(station.currentStop));
        onRouteSelected(station);
    }, []);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const coords = {lat: position.coords.latitude, lng: position.coords.longitude}
                setMapCenter(coords);
                mapFilters.mapCenter = coords;
            });
        } else {
            console.warn("Geolocation is not available in your browser.");
        }

        onFiltersChanged(mapFilters)
    }, []);

    return (
        <Box>
            <Card>
                <Box>
                    <RegionFilters
                        data={mapFilterData || []}
                        filters={mapFilters || {}}
                        isFilterVisible={isFilterVisible}
                        setFilterVisibility={setFilterVisibility}
                        onChange={onFiltersChanged}/>
                </Box>
                <SimpleGrid
                    columns={{md: 3, lg: 5}}
                    gap={1}
                >

                    <GridItem colSpan={2} mr={4} style={{height: '100%'}}>
                        <Box style={
                            {height: '490px', width: '100%', overflow: 'auto'}
                        }>
                            <TransportNetworkFilters
                                data={mapFilterData || []}
                                filters={mapFilters || {}}
                                isFilterVisible={isFilterVisible}
                                setFilterVisibility={setFilterVisibility}
                                onChange={onFiltersChanged}/>
                            <Box my={2}>
                                <strong>Routes & Stations</strong>
                                <Icon as={MdAssistantNavigation} ml='0.5rem' fontSize={'18px'}/>
                            </Box>
                            <RoutesTable data={mapRoutes}
                                         onRouteSelected={onRouteSelected}
                                         onStationSelected={onStationSelected}/>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={3}>
                        <MapContainer
                            style={{
                                width: '100%',
                                height: '100%',
                                maxHeight: '481px',
                                minHeight: '481px'
                            }}
                            routes={mapRoutes || []}
                            center={mapCenter}
                            onDirectionsChanged={setMapCenter}
                        />
                    </GridItem>
                </SimpleGrid>
            </Card>

        </Box>
    )
}

export const initialiseMapFilters = () => {
    return {
        country: '',
        province: '',
        region: ''
    } as MapFilters
};

const initialiseMapFilterData = () => {
    return {
        countries: [],
        provinces: [],
        regions: [],
        providers: []
    } as MapFilterData
};

